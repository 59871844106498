<template>
    <div>
      <section class="content">
        <div class="body_scroll">
          <div class="block-header">
            <div class="row">
              <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Package ID Report</h2>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href=" /"><i class="zmdi zmdi-home"></i> Admin</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0);">Package ID</a>
                  </li>
                  <li class="breadcrumb-item active">Report</li>
                </ul>
                <button
                  class="btn btn-primary btn-icon mobile_menu-new"
                  type="button"
                  @click="$root.toggleSidebar"
                >
                  <i class="zmdi zmdi-sort-amount-desc"></i>
                </button>
              </div>
              <div class="col-lg-5 col-md-6 col-sm-12">
                <button
                  class="btn btn-primary btn-icon float-right right_icon_toggle_btn"
                  type="button"
                >
                  <i class="zmdi zmdi-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
  
          <div class="container-fluid">
            <div class="row clearfix">
              <div class="col-lg-12">
                <div class="card">
                  <div class="body">
                    <div class="table-responsive">
                      <table
                        class="table table-bordered table-striped table-hover"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Package ID</th>
                            <th>Number of SMS</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="loading">
                            <td colspan="4">Loading...</td>
                          </tr>
                          <tr v-else-if="paginatedSms.length === 0">
                            <td colspan="4">No Package ID History Found</td>
                          </tr>
                          <tr
                            v-else
                            v-for="(sms, index) in paginatedSms"
                            :key="sms.id"
                          >
                            <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
                            <td>{{ formatDate(sms.date) }}</td>
                            <td>{{ sms.package_id || "N/A" }}</td>
                            <td>{{ sms.sms_count || "N/A" }}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Package ID</th>
                            <th>Number of SMS</th>
                          </tr>
                        </tfoot>
                      </table>
  
                      <!-- Pagination -->
                      <nav aria-label="Page navigation">
                        <ul class="pagination">
                          <li
                            class="page-item"
                            :class="{ disabled: currentPage === 1 }"
                          >
                            <a
                              class="page-link"
                              href="#"
                              @click.prevent="changePage(1)"
                              >First</a
                            >
                          </li>
                          <li
                            class="page-item"
                            :class="{ disabled: currentPage === 1 }"
                          >
                            <a
                              class="page-link"
                              href="#"
                              @click.prevent="changePage(currentPage - 1)"
                              >Previous</a
                            >
                          </li>
                          <li
                            v-for="page in limitedPageRange"
                            :key="page"
                            class="page-item"
                            :class="{ active: currentPage === page }"
                          >
                            <a
                              class="page-link"
                              href="#"
                              @click.prevent="changePage(page)"
                              >{{ page }}</a
                            >
                          </li>
                          <li
                            class="page-item"
                            :class="{ disabled: currentPage === totalPages }"
                          >
                            <a
                              class="page-link"
                              href="#"
                              @click.prevent="changePage(currentPage + 1)"
                              >Next</a
                            >
                          </li>
                          <li
                            class="page-item"
                            :class="{ disabled: currentPage === totalPages }"
                          >
                            <a
                              class="page-link"
                              href="#"
                              @click.prevent="changePage(totalPages)"
                              >Last</a
                            >
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import SMSService from "../services/smsService";
  import Cookies from "js-cookie";
  import { format } from "date-fns";
  import { useToast } from "vue-toastification";
  export default {
    name: "PackageIdReportPage",
    data() {
      return {
        transactionalSms: [],
        loading: false,
        currentPage: 1,
        perPage: 10,
        totalPages: 1,
      };
    },
    computed: {
      paginatedSms() {
        return this.transactionalSms;
      },
      limitedPageRange() {
        const start = Math.max(1, this.currentPage - 5);
        const end = Math.min(this.totalPages, start + 9);
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
      },
    },
    methods: {
      async fetchTransactional() {
        try {
          const toast = useToast();
          const token = Cookies.get("auth_token");
          const userId = localStorage.getItem("user_id");
          if (!userId) {
            toast.info("User ID is missing. Cannot fetch Topup data.");
            this.$router.push("/");
            return;
          }
          this.loading = true;
  
          const data = {
            client_id: userId,
            page: this.currentPage,
            per_page: this.perPage,
          };
  
          const response = await SMSService.post(
            `dashboard/sms/transaction/history/by/package`,
            data,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          console.log("history", response);
  
          if (response.status === 200) {
            this.transactionalSms = response.data.data || [];
            console.log("length", this.transactionalSms.length);
            this.totalPages = response.data.last_page || 1;
          }
        } catch (err) {
          console.error("Error fetching transactional data:", err);
          this.transactionalSms = [];
        } finally {
          this.loading = false;
        }
      },
  
      async changePage(page) {
        if (page >= 1 && page <= this.totalPages) {
          this.currentPage = page;
          await this.fetchTransactional();
        }
      },
  
      formatDate(dateStr) {
        const date = new Date(dateStr);
        return format(date, "do MMM yyyy h:mm a");
      },
    },
    mounted() {
      this.fetchTransactional();
    },
  };
  </script>
  
  <style scoped>
  .mobile_menu-new {
    display: none;
  }
  @media screen and (max-width: 568px) {
    .mobile_menu-new {
      display: block;
      float: right;
    }
  }
  .text-success {
    color: green;
  }
  .text-danger {
    color: red;
  }
  </style>
  