<template>
  <div class="chart-container">
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PieController,
} from "chart.js";
import { ref, onMounted, watch, defineComponent } from "vue";

ChartJS.register(PieController, Title, Tooltip, Legend, ArcElement);

export default defineComponent({
  name: "PromotionalPieChart",
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props) {
    const chartCanvas = ref(null);
    let chart = null;

    const initChart = () => {
      if (!chartCanvas.value) return;

      if (chart) {
        chart.destroy();
      }

      const ctx = chartCanvas.value.getContext("2d");
      chart = new ChartJS(ctx, {
        type: "pie",
        data: {
          datasets: [
            {
              label: "Promotional Data",
              backgroundColor: ["#FF6384", "#36A2EB", "purple", "#FFCE56"],
              data: props.data || [0, 0, 0, 0],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "bottom",
            },
          },
        },
      });
    };

    watch(
      () => props.data,
      (newData) => {
        if (newData) {
          initChart();
        }
      },
      { deep: true }
    );

    onMounted(() => {
      initChart();
    });

    return {
      chartCanvas,
    };
  },
});
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 300px;
  width: 100%;
}
</style>
