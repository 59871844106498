<template>
  <div>
    <section class="content">
      <div class="body_scroll">
        <div class="block-header">
          <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
              <h2>Contact Sms</h2>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href=" /"><i class="zmdi zmdi-home"></i> Admin</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="javascript:void(0);">Contact</a>
                </li>
                <li class="breadcrumb-item active">Sms</li>
              </ul>
              <!-- <button
                class="btn btn-primary btn-icon mobile_menu"
                type="button"
              >
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button> -->
              <button
                class="btn btn-primary btn-icon mobile_menu-new"
                type="button"
                @click="$root.toggleSidebar"
              >
                <!-- @click="$emit('toggle-sidebar')" -->
                <!-- @click ="$root.toggleSidebar" -->
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
              <button
                class="btn btn-primary btn-icon float-right right_icon_toggle_btn"
                type="button"
              >
                <i class="zmdi zmdi-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <!--   -->

          <div class="main-contact-group-container">
            <div class="main-contact-group-container-upper">
              <p style="font-weight: bold">Contact Groups</p>

              <div class="d-flex flex-column">
                <button
                  class="btn"
                  type="button"
                  data-toggle="modal"
                  data-target="#groupModal"
                >
                  Create new Contact Group
                </button>
                <button
                  class="btn btn-primary"
                  type="button"
                  data-toggle="modal"
                  data-target="#updateModal"
                >
                  Update
                </button>
                <button
                  class="btn btn-danger"
                  type="button"
                  data-toggle="modal"
                  data-target="#deleteModal"
                >
                  Delete
                </button>
              </div>
            </div>
            <!-- <div class="search-div">
              <input
                class="form-control"
                placeholder="Search for Contact Group"
              />
            </div> -->

            <!-- <div
              class="contact-group-inner"
              v-if="contactgroup && contactgroup.length > 0"
            >
              <div
                class="contact-groups-div"
                v-for="group in contactgroup"
                :key="group.id"
                @click="handleGroupClick(group.id)"
              >
                <p style="font-weight: 600">{{ group.name }}</p>
                <p style="font-weight: 700">{{ group.contacts }}</p>
              </div>
            </div> -->

            <ul class="nav nav-pills nav-fill contact-group-list">
              <li
                class="nav-item"
                v-for="group in contactgroup"
                :key="group.id"
                @click="handleGroupClick(group.id)"
              >
                <a class="nav-link contact-group-link">
                  <span class="group-name">{{ group.name }}</span>
                  <span class="group-contacts">{{ group.contacts }}</span>
                </a>
              </li>
            </ul>
          </div>

          <!-- MODAL FOR CREATING A NEW CONTACT GROUP -->

          <div class="modal fade" id="groupModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="title" id="largeModalLabel">Add Contact Group</h4>
                </div>
                <div class="modal-body">
                  <div class="body">
                    <form
                      id="form_validation"
                      @submit.prevent="CreateContactGroup"
                    >
                      <div class="form-group form-float">
                        <label class="fw-bold">Name of Contact Group</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Name of contact group"
                          name="group"
                          required
                          v-model="contact_group"
                        />
                      </div>

                      <div class="form-group form-float">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Country"
                          name="country"
                          required
                          v-model="country"
                          style="cursor: not-allowed"
                        />
                      </div>
                      <div class="form-group form-float">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Mobile Number eg. 0784******"
                          name="mobile-number"
                          required
                          v-model="mobileNumber"
                        />
                      </div>
                      <div class="form-group form-float">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="First Name"
                          name="first_name"
                          required
                          v-model="firstName"
                        />
                      </div>

                      <div class="form-group form-float">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Last Name"
                          name="last_name"
                          required
                          v-model="lastName"
                        />
                      </div>

                      <button
                        class="btn btn-raised btn-primary waves-effect"
                        type="submit"
                      >
                        <span v-if="loading">Loading...</span>
                        <span v-else>Create Contact Group</span>
                      </button>
                    </form>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger waves-effect"
                    data-dismiss="modal"
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- MODAL FOR CREATING A NEW CONTACT GROUP -->

          <!-- MODAL FOR UPDATING A CONTACT GROUP -->

          <div class="modal fade" id="updateModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="title" id="largeModalLabel">
                    Update Contact Group
                  </h4>
                </div>
                <div class="modal-body">
                  <div class="body">
                    <form
                      id="form_validation"
                      @submit.prevent="UpdateContactGroup"
                    >
                      <div class="form-group form-float">
                        <label class="fw-bold"
                          >Choose Contact Group To Update</label
                        >

                        <select
                          class="form-control"
                          required
                          @change="handleUpdateGroupChange"
                          v-if="contactgroup && contactgroup.length > 0"
                          v-model="selectedUpdateGroupId"
                        >
                          <option disabled value="">
                            Choose Contact Group
                          </option>
                          <option
                            v-for="group in contactgroup"
                            :key="group.id"
                            :value="group.id"
                          >
                            {{ group.name }}
                          </option>
                        </select>
                      </div>

                      <div class="form-group form-float">
                        <label class="fw-bold">New Contact Group name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Name of contact group"
                          name="group"
                          required
                          v-model="update_contact_name"
                        />
                      </div>

                      <button
                        class="btn btn-raised btn-primary waves-effect"
                        type="submit"
                      >
                        <span v-if="loading">Loading...</span>
                        <span v-else>Update Contact Group</span>
                      </button>
                    </form>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger waves-effect"
                    data-dismiss="modal"
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- MODAL FOR UPDATING A CONTACT GROUP -->

          <!-- MODAL FOR DELETING A CONTACT GROUP -->

          <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="title" id="largeModalLabel">
                    Delete Contact Group
                  </h4>
                </div>
                <div class="modal-body">
                  <div class="body">
                    <form
                      id="form_validation"
                      @submit.prevent="DeleteContactGroup"
                    >
                      <div class="form-group form-float">
                        <label class="fw-bold"
                          >Choose Contact Group To Delete</label
                        >
                        <select
                          v-model="selectedDeleteGroupId"
                          @change="handleDeleteGroupChange"
                          v-if="contactgroup && contactgroup.length > 0"
                          class="form-control"
                          required
                        >
                          <option disabled value="">
                            Choose Contact Group
                          </option>
                          <option
                            v-for="group in contactgroup"
                            :key="group.id"
                            :value="group.id"
                          >
                            {{ group.name }}
                          </option>
                        </select>
                      </div>

                      <button
                        class="btn btn-raised btn-primary waves-effect"
                        type="submit"
                      >
                        <span v-if="loading">Loading...</span>
                        <span v-else>Delete Contact Group</span>
                      </button>
                    </form>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger waves-effect"
                    data-dismiss="modal"
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- MODAL FOR DELETING A CONTACT GROUP -->

          <!-- MODAL FOR UPDATING A SINGLE CONTACT -->

          <div
            class="modal fade"
            id="editContactModal"
            tabindex="-1"
            role="dialog"
          >
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="title" id="largeModalLabel">
                    Update Contact for
                    {{ selectedContact ? selectedContact.msisdn : "" }}
                  </h4>
                </div>
                <div class="modal-body">
                  <div class="body">
                    <form
                      id="form_validation"
                      v-if="selectedContact"
                      @submit.prevent="UpdateSingleContact"
                    >
                      <div class="form-group form-float">
                        <label class="fw-bold">First Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="First Name"
                          name="group"
                          required
                          v-model="selectedContact.first_name"
                        />
                      </div>

                      <div class="form-group form-float">
                        <label class="fw-bold">Last Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Name of contact group"
                          name="group"
                          required
                          v-model="selectedContact.other_name"
                        />
                      </div>

                      <button
                        class="btn btn-raised btn-primary waves-effect"
                        type="submit"
                      >
                        <span v-if="loading">Loading...</span>
                        <span v-else>Update Contact Name</span>
                      </button>
                    </form>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger waves-effect"
                    data-dismiss="modal"
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- MODAL FOR UPDATING A SINGLE CONTACT -->

          <!-- MODAL FOR MUTING A SINGLE CONTACT -->

          <div
            class="modal fade"
            id="muteContactModal"
            tabindex="-1"
            role="dialog"
          >
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="title" id="largeModalLabel">
                    <div v-if="selectedContact && selectedContact.status == 1">
                      Mute Contact
                      {{ selectedContact ? selectedContact.msisdn : "" }}
                    </div>

                    <div v-else>
                      Unmute Contact
                      {{ selectedContact ? selectedContact.msisdn : "" }}
                    </div>
                  </h4>
                </div>
                <div class="modal-body">
                  <div class="body">
                    <form
                      id="form_validation"
                      v-if="selectedContact"
                      @submit.prevent="muteSingleContact"
                    >
                      <div class="form-group form-float">
                        <label class="fw-bold">Contact</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="First Name"
                          name="group"
                          required
                          readOnly
                          v-model="selectedContact.msisdn"
                          style="cursor: not-allowed"
                        />
                      </div>

                      <button
                        class="btn btn-raised btn-primary waves-effect"
                        type="submit"
                      >
                        <span v-if="loading">Loading...</span>
                        <span v-else>{{
                          selectedContact && selectedContact.status == 1
                            ? "Mute Contact"
                            : "Unmute Contact"
                        }}</span>
                      </button>
                    </form>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger waves-effect"
                    data-dismiss="modal"
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- MODAL FOR MUTING A SINGLE CONTACT -->

          <!-- MODAL FOR DELETING A SINGLE CONTACT -->

          <div
            class="modal fade"
            id="deleteContactModal"
            tabindex="-1"
            role="dialog"
          >
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="title" id="largeModalLabel">
                    Delete Contact
                    {{ selectedContact ? selectedContact.msisdn : "" }}
                  </h4>
                </div>
                <div class="modal-body">
                  <div class="body">
                    <form
                      id="form_validation"
                      v-if="selectedContact"
                      @submit.prevent="deleteSingleContact"
                    >
                      <div class="form-group form-float">
                        <label class="fw-bold">Contact</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="First Name"
                          name="group"
                          required
                          readOnly
                          v-model="selectedContact.msisdn"
                          style="cursor: not-allowed"
                        />
                      </div>

                      <button
                        class="btn btn-raised btn-primary waves-effect"
                        type="submit"
                      >
                        <span v-if="loading">Loading...</span>
                        <span v-else> Delete Contact</span>
                      </button>
                    </form>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger waves-effect"
                    data-dismiss="modal"
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- MODAL FOR DELETING A SINGLE CONTACT -->

          <div class="row clearfix">
            <div class="col-lg-12">
              <div class="card">
                <div class="header">
                  <button
                    type="button"
                    class="btn btn-default waves-effect m-r-20"
                    data-toggle="modal"
                    data-target="#largeModal"
                  >
                    Add Contacts
                  </button>

                  <button
                    type="button "
                    class="btn btn-success waves-effect m-r-20"
                    @click="downloadList"
                  >
                    <!-- @click ='downloadCsv(selectedGroupId)' -->
                    Download List
                  </button>

                  <button
                    type="button "
                    class="btn btn-primary waves-effect m-r-20"
                    @click="openModal"
                  >
                    Send Message
                  </button>

                  <div
                    class="modal fade"
                    id="largeModal"
                    tabindex="-1"
                    role="dialog"
                  >
                    <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="title" id="largeModalLabel">
                            Add Contacts
                          </h4>
                        </div>
                        <div class="modal-body">
                          <div class="body">
                            <form
                              id="form_validation"
                              @submit.prevent="CreateContact"
                            >
                              <div class="form-group form-float">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Country"
                                  name="country"
                                  required
                                  v-model="country"
                                  style="cursor: not-allowed"
                                />
                              </div>
                              <div class="form-group form-float">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Mobile Number eg. 0784******"
                                  name="mobile-number"
                                  required
                                  v-model="mobileNumber"
                                />
                              </div>
                              <div class="form-group form-float">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="First Name"
                                  name="first_name"
                                  required
                                  v-model="firstName"
                                />
                              </div>

                              <div class="form-group form-float">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Last Name"
                                  name="last_name"
                                  required
                                  v-model="lastName"
                                />
                              </div>

                              <div class="form-group form-float">
                                <select
                                  class="form-control"
                                  required
                                  v-model="selectedGroupName"
                                  v-if="contactgroup && contactgroup.length > 0"
                                  @change="logGroupName"
                                >
                                  <option disabled value="">
                                    Select Contact Group
                                  </option>
                                  <option
                                    v-for="group in contactgroup"
                                    :key="group.id"
                                    :value="group.name"
                                  >
                                    {{ group.name }}
                                  </option>
                                </select>
                              </div>

                              <button
                                class="btn btn-raised btn-primary waves-effect"
                                type="submit"
                              >
                                <span v-if="loading">Loading...</span>
                                <span v-else>SUBMIT</span>
                              </button>
                            </form>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-danger waves-effect"
                            data-dismiss="modal"
                          >
                            CLOSE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="body">
                  <div class="table-responsive">
                    <h2 class="text-center">
                      Displaying Data for {{ displayedGroupName }}
                    </h2>
                    <table
                      class="table table-bordered table-striped table-hover js-basic-example dataTable"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Mobile Number</th>
                          <th>First Name</th>
                          <th>Other Name</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-if="loading">
                          <td colspan="7">Loading...</td>
                        </tr>

                        <tr v-else-if="contacts == null">
                          <td colspan="7">
                            No Data for {{ displayedGroupName }}
                          </td>
                        </tr>

                        <tr
                          v-else
                          v-for="(contact, index) in contacts"
                          :key="contact.id"
                        >
                          <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
                          <td>
                            {{
                              contact && contact.msisdn ? contact.msisdn : "N/A"
                            }}
                          </td>
                          <td>
                            {{
                              contact && contact.first_name
                                ? contact.first_name
                                : "N/A"
                            }}
                          </td>
                          <td>
                            {{
                              contact && contact.other_name
                                ? contact.other_name
                                : "N/A"
                            }}
                          </td>
                          <td>{{ formatDate(contact.created) }}</td>
                          <td
                            :class="{
                              'active-status': contact && contact.status == 1,
                              'inactive-status': contact && contact.status != 1,
                            }"
                          >
                            {{
                              contact && contact.status == 1
                                ? "Active"
                                : "Muted"
                            }}
                          </td>
                          <td>
                            <div class="choose-container">
                              <div
                                class="choose-text"
                                @click="toggleButtons(index)"
                              >
                                Choose
                              </div>
                              <div
                                class="button-group"
                                v-show="activeIndex === index"
                              >
                                <button
                                  class="btn btn-primary"
                                  data-toggle="modal"
                                  data-target="#editContactModal"
                                  @click="setSelectedContact(contact)"
                                >
                                  Update
                                </button>
                                <button
                                  class="btn btn-danger"
                                  data-toggle="modal"
                                  data-target="#deleteContactModal"
                                  @click="setSelectedContact(contact)"
                                >
                                  Delete
                                </button>
                                <button
                                  class="btn btn-warning"
                                  data-toggle="modal"
                                  data-target="#muteContactModal"
                                  @click="setSelectedContact(contact)"
                                >
                                  {{ contact.status == 1 ? "Mute" : "Unmute" }}
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>#</th>
                          <th>Mobile Number</th>
                          <th>First Name</th>
                          <th>Other Name</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </tfoot>
                    </table>

                    <nav aria-label="Page navigation">
                      <ul class="pagination">
                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(1)"
                            >First</a
                          >
                        </li>

                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(currentPage - 1)"
                            >Previous</a
                          >
                        </li>

                        <li
                          v-for="page in limitedPageRange"
                          :key="page"
                          class="page-item"
                          :class="{ active: currentPage === page }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(page)"
                            >{{ page }}</a
                          >
                        </li>

                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === totalPages }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(currentPage + 1)"
                            >Next</a
                          >
                        </li>

                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === totalPages }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(totalPages)"
                            >Last</a
                          >
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <SendSmsPage v-if="showModal" @close="closeModal" />
</template>

<script>
import { useToast } from "vue-toastification";
import { format } from "date-fns";
import Cookies from "js-cookie";
import SmsService from "../services/smsService";
import SendSmsPage from "./Modals/SendMessage.vue";
export default {
  name: "ContactSMSPage",
  components: {
    SendSmsPage,
  },
  data() {
    return {
      contacts: [],
      country: "ke",
      mobileNumber: "",
      firstName: "",
      lastName: "",
      contactgroup: [],
      selectedUpdateGroupId: null,
      selectedDeleteGroupId: "",
      contact_group: "",
      update_contact_name: "",
      selectedGroupName: "",
      selectedGroupId: "",
      loading: false,
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      displayedGroupName: "",
      activeIndex: null,
      selectedContact: null,
      showModal: false,
    };
  },

  async mounted() {
    await this.fetchContactGroups();

    if (this.contactgroup && this.contactgroup.length > 0) {
      const firstGroupId = this.contactgroup[0].id;
      this.FetchContacts(firstGroupId);
    }
  },
  computed: {
    limitedPageRange() {
      const start = Math.max(1, this.currentPage - 5);
      const end = Math.min(this.totalPages, start + 9);
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
  },

  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },

    setSelectedContact(contact) {
      this.selectedContact = { ...contact };
    },

    toggleButtons(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
      console.log("swd");
    },
    async fetchContactGroups() {
      try {
        const token = Cookies.get("auth_token");

        const response = await SmsService.post(
          `contact/group`,
          {},
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("contact Groups", response.data.message);
        // let groupname = response.data.message;
        // this.contactgroupname = groupname.map((item) => item.name);
        // console.log("contact group names", this.contactgroupname);

        if (response.data.status == 200) {
          this.contactgroup = response.data.message;
          // console.log('data comming in mf', this.contactgroup)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async FetchContacts(groupId) {
      try {
        this.loading = true;
        const token = Cookies.get("auth_token");

        const response = await SmsService.post(
          "contact/group/list",
          {
            sort: "contact_group_msisdn.id|desc",
            id: groupId,
            per_page: this.perPage,
            page: this.currentPage,
            // search:'wan'
          },
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Specific Contact Groups:", response);
        if (response.status == 202) {
          this.contacts = response.data.data;
          this.totalPages = response.data.last_page;
        }
      } catch (err) {
        console.log("Error fetching contact group:", err);
      } finally {
        this.loading = false;
      }
    },
    async changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        // await this.FetchContacts(this.contactgroup[0].id);
        await this.FetchContacts(this.selectedGroupId);
      }
    },
    handleGroupClick(groupId) {
      this.selectedGroupId = groupId;
      const selectedGroup = this.contactgroup.find(
        (group) => group.id === groupId
      );
      if (selectedGroup) {
        this.displayedGroupName = selectedGroup.name;
        this.FetchContacts(groupId);
        // this.fetchAllContactSmsForDownload(groupId)
      }
    },

    formatDate(dateStr) {
      const date = new Date(dateStr);
      return format(date, "do MMM yyyy h:mm a");
    },

    async CreateContact() {
      const toast = useToast();
      if (
        !this.country ||
        !this.firstName ||
        !this.lastName ||
        !this.mobileNumber ||
        !this.selectedGroupName
      ) {
        toast.warning("Please fill all fields");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");

        const data = {
          name: this.selectedGroupName,
          contacts: [
            {
              country_code: this.country,
              msisdn: this.mobileNumber,
              first_name: this.firstName,
              other_name: this.lastName,
            },
          ],
        };

        const response = await SmsService.post("contact/create", data, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });
        console.log(response);

        if (response.status == 201) {
          toast.success(response.data.message);
          // this.FetchContacts(this.selectedGroupId);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async CreateContactGroup() {
      const toast = useToast();

      if (
        !this.contact_group ||
        !this.country ||
        !this.mobileNumber ||
        !this.firstName ||
        !this.lastName
      ) {
        toast.warning("Please Fill all Fields");

        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");

        const data = {
          contacts: [
            {
              country_code: this.country,
              msisdn: this.mobileNumber,
              first_name: this.firstName,
              other_name: this.lastName,
            },
          ],
          name: this.contact_group,
        };

        const response = await SmsService.post("contact/create", data, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });
        console.log(response);

        if (response.status == 201) {
          toast.success(response.data.message);
          this.fetchContactGroups();
          // this.FetchContacts(this.selectedGroupId);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleUpdateGroupChange() {
      if (this.selectedUpdateGroupId) {
        console.log("Selected Group Name:", this.selectedUpdateGroupId);
      }
    },

    async UpdateContactGroup() {
      const toast = useToast();

      if (!this.update_contact_name || !this.selectedUpdateGroupId) {
        toast.warning("Please Fill all Fields");

        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");

        const data = {
          contact_group_id: this.selectedUpdateGroupId,
          name: this.update_contact_name,
        };

        const response = await SmsService.post("contact/group/update", data, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });

        console.log(response);

        if (response.status == 201) {
          toast.success(response.data);
          this.fetchContactGroups();

          // setTimeout(() => {
          //   window.location.reload()
          // }, 1000)
          await this.fetchContactGroups();
        }
      } catch (err) {
        console.log("Update error", err);
      } finally {
        this.loading = false;
      }
    },

    handleDeleteGroupChange() {
      const selectedGroup = this.contactgroup.find(
        (group) => group.id === this.selectedDeleteGroupId
      );

      console.log("Selected Group ID for Update:", this.selectedDeleteGroupId);
      console.log("Selected Group Details:", selectedGroup);
    },

    logGroupName() {
      if (this.selectedGroupName) {
        console.log("Selected Group Name:", this.selectedGroupName);
      }
    },

    async DeleteContactGroup() {
      const toast = useToast();

      if (!this.selectedDeleteGroupId) {
        toast.warning("Please Eneter the Contact Group");
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");

        const data = {
          contact_group_id: this.selectedDeleteGroupId,
        };

        const response = await SmsService.post("contact/group/delete", data, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });

        console.log(response);

        if (response.status == 201) {
          toast.success(response.data);
          this.fetchContactGroups();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async downloadList() {
      const csvContent = this.convertToCSV(this.contacts);
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", "contacts.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },

    convertToCSV(contacts) {
      const header = [
        "#",
        "Mobile Number",
        "First Name",
        "Other Name",
        "Date",
        "Status",
      ];
      const rows = contacts.map((contact, index) => [
        (this.currentPage - 1) * this.perPage + index + 1,
        contact.msisdn || "N/A",
        contact.first_name || "N/A",
        contact.other_name || "N/A",
        this.formatDate(contact.created),
      ]);
      return [header, ...rows].map((row) => row.join(",")).join("\n");
    },

    // async downloadCsv(groupId) {
    //   console.log("Download button clicked");
    //   const allData = await this.fetchAllContactSmsForDownload(groupId);
    //   console.log("Fetched data for CSV:", allData);

    //   if (allData && allData.length > 0) {
    //     const csvContent = this.convertToCsv(allData);
    //     // console.log("CSV Content:", csvContent);

    //     const blob = new Blob([csvContent], {
    //       type: "text/csv;charset=utf-8;",
    //     });
    //     const url = URL.createObjectURL(blob);

    //     const link = document.createElement("a");
    //     link.setAttribute("href", url);
    //     link.setAttribute("download", "transactional_sms.csv");
    //     link.style.visibility = "hidden";
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //   } else {
    //     console.log("No data available for CSV export.");
    //   }
    // },

    // async fetchAllContactSmsForDownload(groupId) {
    //   try {
    //     const token = Cookies.get("auth_token");
    //     this.loading = true;

    //     const response = await SmsService.post(
    //       `contact/group/list`,
    //       {
    //         id: groupId,
    //         page: 1,
    //         per_page: 50000,
    //       },
    //       {
    //         headers: {
    //           Authorization: token,
    //         },
    //       }
    //     );

    //     console.log("Fetched all promotional data for CSV:", response);

    //     if (response.status === 200) {
    //       return response.data.data;
    //     }
    //   } catch (err) {
    //     console.error("Error fetching data:", err);
    //   } finally {
    //     this.loading = false;
    //   }
    // },

    // convertToCsv(data) {
    //   const headers = [
    //     "Mobile Number",
    //     "First Name",
    //     "Other Name",
    //     "Date",

    //   ];
    //   const csvRows = [
    //     headers.join(","),
    //     ...data.map((item) =>
    //       [
    //         this.formatDate(item.created),
    //         item.msisdn || "N/A",
    //         item.first_name || "N/A",
    //         item.other_name || "N/A",

    //       ].join(",")
    //     ),
    //   ];
    //   return csvRows.join("\n");
    // },

    watch: {
      selectedUpdateGroupId: {
        handler(newVal) {
          console.log("Contact group updated:", newVal);
        },
        deep: true,
      },
    },

    // UPDATING A SINGLE CONTACT

    async UpdateSingleContact() {
      const toast = useToast();

      if (
        !this.selectedContact.first_name ||
        !this.selectedContact.other_name
      ) {
        toast.warning("Please fill in all fields.");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");

        const data = {
          contact_group_msisdn_id: this.selectedContact.contact_id,
          first_name: this.selectedContact.first_name,
          other_name: this.selectedContact.other_name,
        };

        console.log(data);

        const response = await SmsService.post(
          "contact/group/msisdn/update",
          data,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        console.log(response);

        if (response.status === 200) {
          toast.success(response.data);

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } catch (err) {
        console.log("Update error", err);
        if (err.status == 422) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },

    // MUTE SINGLE CONTACT

    async muteSingleContact() {
      const toast = useToast();
      try {
        this.loading = true;
        const token = Cookies.get("auth_token");

        const newStatus = this.selectedContact.status === 1 ? 0 : 1;
        const data = {
          contact_group_msisdn_id: this.selectedContact.contact_id,
          status: newStatus,
        };

        console.log(data);

        const response = await SmsService.post(
          "contact/group/msisdn/status",
          data,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        console.log(response);

        if (response.status === 201) {
          toast.success(response.data);

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } catch (err) {
        console.log("Update error", err);
        if (err.status == 422) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },

    // DELETING A SINGLE CONTACT

    async deleteSingleContact() {
      const toast = useToast();
      try {
        this.loading = true;
        const token = Cookies.get("auth_token");

        const data = {
          contact_group_msisdn_id: this.selectedContact.contact_id,
        };

        console.log(data);

        const response = await SmsService.post(
          "contact/group/msisdn/delete",
          data,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        console.log(response);

        if (response.status === 201) {
          toast.success(response.data);

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } catch (err) {
        console.log("Update error", err);
        if (err.status == 422) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.contact-groups-div {
  background-color: #e5e5e5;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  width: calc(50% - 10px);
  margin-top: 10px;
}

.main-contact-group-container {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
}

.main-contact-group-container-upper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}

.search-div {
  display: flex;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}

.contact-group-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.active-status {
  color: green;
  font-weight: bold;
}

.inactive-status {
  color: red;
  font-weight: bold;
}

.choose-container {
  position: relative;
  text-align: center;
  cursor: pointer;
}

.button-group {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 2px;
  z-index: 100;
  display: block;
  background-color: white;
  padding: 4px;
}

.mobile_menu-new {
  display: none;
}

.contact-group-list {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  scrollbar-width: thin;
  scrollbar-color: #ccc #f1f1f1;
  /* scrollbar-color: #ccc black;  */
}

.contact-group-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 120px;
  max-width: 150px;
  height: 100px;
  background-color: #f1f1f1;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.contact-group-list::-webkit-scrollbar {
  height: 8px;
}

.contact-group-list::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.contact-group-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.group-name {
  font-weight: 600;
}

.group-contacts {
  font-weight: 700;
  color: #666;
}

@media screen and (max-width: 568px) {
  .contact-group-inner {
    width: 100%;
  }
  .mobile_menu-new {
    display: block;
    float: right;
  }
}
</style>
