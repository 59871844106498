import { createStore } from "vuex";
import RegisterModule from "./modules/register";
import LoginModule from "./modules/login";
import GlobalAuth from "./modules/GlobalAuth";
export default createStore({
  modules: {
    register: RegisterModule,
    login: LoginModule,
    globalAuth: GlobalAuth,
  },
});
