<template>
  <div>
    <section class="content">
      <div class="body_scroll">
        <div class="block-header">
          <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
              <h2>DND Listed</h2>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href=" /"><i class="zmdi zmdi-home"></i> Admin</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="javascript:void(0);">DND</a>
                </li>
                <li class="breadcrumb-item active">Listed</li>
              </ul>
              <!-- <button
                class="btn btn-primary btn-icon mobile_menu"
                type="button"
              >
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button> -->
              <button
                class="btn btn-primary btn-icon mobile_menu-new"
                type="button"
                @click="$root.toggleSidebar"
              >
                <!-- @click="$emit('toggle-sidebar')" -->
                <!-- @click ="$root.toggleSidebar" -->
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
              <button
                class="btn btn-primary btn-icon float-right right_icon_toggle_btn"
                type="button"
              >
                <i class="zmdi zmdi-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <!--   -->
          <div class="row clearfix">
            <div class="col-lg-12">
              <div class="card">
                <div class="header">
                  <button
                    type="button"
                    class="btn btn-primary waves-effect m-r-20"
                    data-toggle="modal"
                    data-target="#largeModal"
                  >
                    CREATE NEW
                  </button>
                  <div
                    class="modal fade"
                    id="largeModal"
                    tabindex="-1"
                    role="dialog"
                  >
                    <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="title" id="largeModalLabel">
                            Add Contact To DND
                          </h4>
                        </div>
                        <div class="modal-body">
                          <div class="body">
                            <form id="form_validation" @submit.prevent="addDnd">
                              <div class="form-group form-float">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Phone Number"
                                  name="phonenumber"
                                  required
                                  v-model="phonenumber"
                                />
                              </div>

                              <button
                                class="btn btn-raised btn-primary waves-effect"
                                type="submit"
                              >
                                <span v-if="loading">Loading...</span
                                ><span v-else>SUBMIT</span>
                              </button>
                            </form>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-danger waves-effect"
                            data-dismiss="modal"
                          >
                            CLOSE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="body">
                  <div class="table-responsive">
                    <table
                      class="table table-bordered table-striped table-hover js-basic-example dataTable"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Contact</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-if="loading">
                          <td colspan="2">Loading...</td>
                        </tr>

                        <tr v-else-if="dndSms.length === 0">
                          <td colspan="7">No DND Found</td>
                        </tr>

                        <tr v-for="(sms, index) in dndSms" :key="sms.id">
                          <td>{{ index + 1 }}</td>
                          <td>{{ sms.msisdn || "N/A" }}</td>
                          <td>
                            <button
                              class="btn btn-danger"
                              @click="openDeleteModal(sms)"
                              data-toggle="modal"
                              data-target="#deleteModal"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>#</th>
                          <th>Contact</th>
                          <th>Action</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>

                <div
                  v-if="showDeleteModal"
                  class="modal fade show"
                  id="deleteModal"
                  tabindex="-1"
                  role="dialog"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title">Confirm Delete</h5>
                      </div>
                      <div class="modal-body">
                        <p>
                          Are you sure you want to delete
                          {{
                            selectedContact && selectedContact.msisdn
                              ? selectedContact.msisdn
                              : "N/A"
                          }}?
                        </p>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger"
                          @click="deleteContact"
                        >
                          <span v-if="loading">Loading...</span
                          ><span v-else>YES</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import SMSService from "../services/smsService";
import Cookies from "js-cookie";
import router from "../router/index";
import { mapActions } from "vuex";

export default {
  name: "SendSmsPage",
  data() {
    return {
      isScheduled: false,
      dndSms: [],
      loading: false,
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      phonenumber: "",
      selectedContact: null,
      showDeleteModal: false,
    };
  },
  methods: {
    ...mapActions("login", ["LogOutUser"]),

    async fetchDndSms() {
      const toast = useToast();
      try {
        const token = Cookies.get("auth_token");
        this.loading = true;

        const response = await SMSService.post(
          `blacklist/all`,
          {
            page: this.currentPage,
            per_page: this.perPage,
            start_date: this.startDate,
            end_date: this.endDate,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log("dnd bro", response.data.message);

        if (response.status == 200) {
          this.dndSms = response.data.message || [];
        }
      } catch (err) {
        console.log(err);
        if (
          err.response.status == 428 ||
          err.response.status == 401 ||
          err.response.status == 403
        ) {
          toast.info("Token Expired");
          this.LogOutUser();
          router.push("/");
        }
      } finally {
        this.loading = false;
      }
    },
    async addDnd() {
      const toast = useToast();

      try {
        const data = {
          msisdn: this.phonenumber,
        };

        const token = Cookies.get("auth_token");
        this.loading = true;

        const response = await SMSService.post("blacklist/add", data, {
          headers: {
            Authorization: token,
          },
        });

        console.log(response);
        if (response.status == 201) {
          toast.success(response.data.message);
          // this.phonenumber = ''
          this.fetchDndSms();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = true;
      }
    },

    openDeleteModal(contact) {
      this.selectedContact = contact;
      this.showDeleteModal = true;
    },

    closeDeleteModal() {
      this.showDeleteModal = false;
      this.selectedContact = null;
    },

    async deleteContact() {
      const toast = useToast();
      try {
        if (!this.selectedContact || !this.selectedContact.id) {
          toast.warning("Invalid contact. Unable to delete.");
          return;
        }

        const token = Cookies.get("auth_token");
        this.loading = true;

        const response = await SMSService.post(
          `blacklist/${this.selectedContact.id}/delete`,
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log(response);

        if (response.status == 200) {
          toast.success(
            response.data.message || "Dnd Contact Deleted Successfully"
          );
          this.fetchDndSms();
        }
      } catch (err) {
        console.error(err);
        if (err.status == 405) {
          toast.error("Method not Allowed");
        }
        toast.error("Failed to delete contact");
      } finally {
        this.loading = false;
        this.closeDeleteModal();
      }
    },
  },
  mounted() {
    this.fetchDndSms();
  },
};
</script>

<style scoped>
.mobile_menu-new {
  display: none;
}

@media screen and (max-width: 568px) {
  .mobile_menu-new {
    display: block;
    float: right;
  }
}
</style>
