<template>
  <div class="authentication">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-sm-12">
          <form class="card auth_form" @submit.prevent="registerUser">
            <div class="header">
              <img class="logo" src="/assets/images/logo.svg" alt="" />
              <h5>Register</h5>
            </div>
            <div class="body">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Organization name"
                  v-model="organizationName"
                  :disabled="loading"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="zmdi zmdi-account-circle"></i>
                  </span>
                </div>
              </div>

              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Fullname"
                  v-model="fullname"
                  :disabled="loading"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="zmdi zmdi-account-circle"></i>
                  </span>
                </div>
              </div>

              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Email Address"
                  v-model="email"
                  :disabled="loading"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="zmdi zmdi-account-circle"></i>
                  </span>
                </div>
              </div>

              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Mobile Number"
                  v-model="mobileNumber"
                  :disabled="loading"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="zmdi zmdi-account-circle"></i>
                  </span>
                </div>
              </div>

              <div class="input-group mb-3">
                <input
                  type="password"
                  class="form-control"
                  placeholder="Password"
                  v-model="password"
                  :disabled="loading"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <a
                      href="forgot-password.html"
                      class="forgot"
                      title="Forgot Password"
                    >
                      <i class="zmdi zmdi-lock"></i>
                    </a>
                  </span>
                </div>
              </div>

              <div class="input-group mb-3">
                <input
                  type="password"
                  class="form-control"
                  placeholder="Confirm Password"
                  v-model="confirmPassword"
                  :disabled="loading"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <a
                      href="forgot-password.html"
                      class="forgot"
                      title="Forgot Password"
                    >
                      <i class="zmdi zmdi-lock"></i>
                    </a>
                  </span>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary btn-block waves-effect waves-light"
              >
                <span v-if="loading">Loading...</span>
                <span v-else>SIGN UP</span>
              </button>
            </div>

            <div class="ml-4">
              <p>
                Already Registered?
                <!-- <a href="/"> Sign In </a> -->
                <router-link to="/">Sign In</router-link>
              </p>
            </div>
          </form>
        </div>
        <div class="col-lg-8 col-sm-12">
          <div class="card">
            <img src="/assets/images/signin.svg" alt="Sign In" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";

export default {
  name: "RegisterPage",
  data() {
    return {
      organizationName: "",
      fullname: "",
      email: "",
      mobileNumber: "",
      password: "",
      confirmPassword: "",
    };
  },
  computed: {
    ...mapGetters("register", ["loading", "error", "registrationStatus"]),
  },
  watch: {
    registrationStatus(status) {
      const toast = useToast();

      if (status === "success") {
        toast.success("Registration successful! Redirecting to sign in...");
        setTimeout(() => {
          this.$router.push("/");
        }, 1000);
      }

      if (this.error) {
        toast.error(this.error);
      }
    },
  },
  methods: {
    ...mapActions("register", ["RegisterUser"]),
    async registerUser() {
      const toast = useToast();

      if (
        !this.organizationName ||
        !this.fullname ||
        !this.email ||
        !this.mobileNumber ||
        !this.password ||
        !this.confirmPassword
      ) {
        toast.warning("All Fields are Required");
        return;
      }

      if (this.password.length < 6) {
        toast.warning("Password must be at least 6 characters");
        return;
      }

      if (this.password !== this.confirmPassword) {
        toast.warning("Passwords do not match!");
        return;
      }

      const registrationData = {
        full_name: this.fullname,
        msisdn: this.mobileNumber,
        email: this.email,
        company_name: this.organizationName,
        country_code: "KE",
        password: this.password,
      };

      await this.RegisterUser(registrationData);
    },
  },
};
</script>
