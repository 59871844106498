import RegisterService from "../../services/Auth";

const state = {
  registrationStatus: "",
  user: null,
  loading: false,
  error: null,
};

const getters = {
  registrationStatus: (state) => state.registrationStatus,
  user: (state) => state.user,
  loading: (state) => state.loading,
  error: (state) => state.error,
};

const actions = {
  async RegisterUser({ commit }, registrationData) {
    commit("setLoading", true);
    commit("setError", null);

    try {
      const response = await RegisterService.post("/signup", registrationData);
      // console.log(response);

      if (response.status === 201) {
        commit("setUser", response.data.message);
        commit("setRegistrationStatus", "success");
        commit("setError", null);
      } else {
        commit("setError", "Unexpected status code received.");
      }
    } catch (error) {
      // console.log(error);
      commit("setRegistrationStatus", "failed");

      if (error.response) {
        const { status, data } = error.response;

        switch (status) {
          case 400:
            commit(
              "setError",
              data.message || "Bad Request. Please check the input."
            );
            break;

          case 422:
            commit(
              "setError",
              data.message ||
                "Unprocessable Entity. Please check the form data."
            );
            break;
          case 500:
            commit(
              "setError",
              data.message || "Internal Server Error. Please try again later."
            );
            break;
          default:
            commit(
              "setError",
              data.message || "An error occurred. Please try again."
            );
        }
      } else {
        commit("setError", "Network error. Please check your connection.");
      }
    } finally {
      commit("setLoading", false);
    }
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },

  setLoading(state, status) {
    state.loading = status;
  },

  setRegistrationStatus(state, status) {
    state.registrationStatus = status;
  },

  setError(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
