<template>
    <div class="authentication">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-sm-12">
                    <form class="card auth_form">
                        <div class="header">
                            <img class="logo" src="/assets/images/logo.png" alt="">
                            <h5>Error 404</h5>
                            <span>Page not found</span>
                        </div>
                        <div class="body">
                            <a href="/" class="btn btn-primary btn-block waves-effect waves-light">GO TO HOMEPAGE</a>                        
                            <div class="signin_with mt-3">
                                <a href="javascript:void(0);" class="link">Need Help?</a>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-lg-8 col-sm-12">
                    <div class="card">
                        <img src="/assets/images/404.svg" alt="404" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound',
  };
  </script>
  