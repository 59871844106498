<template>
  <div>
    <section class="content">
      <div class="body_scroll">
        <div class="block-header">
          <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
              <h2>Account Topup</h2>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href=" /"><i class="zmdi zmdi-home"></i> Admin</a>
                </li>
                <li class="breadcrumb-item">Account</li>
                <li class="breadcrumb-item active">Topup</li>
              </ul>
              <!-- <button
                class="btn btn-primary btn-icon mobile_menu"
                type="button"
              >
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button> -->
              <button
                class="btn btn-primary btn-icon mobile_menu-new"
                type="button"
                @click="$root.toggleSidebar"
              >
                <!-- @click="$emit('toggle-sidebar')" -->
                <!-- @click ="$root.toggleSidebar" -->
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
              <button
                class="btn btn-primary btn-icon float-right right_icon_toggle_btn"
                type="button"
              >
                <i class="zmdi zmdi-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row clearfix">
            <div class="col-md-12">
              <div class="card">
                <div class="header">
                  <h2><strong>Top Up Your</strong> Account Via Mpesa</h2>
                </div>
                <div class="body">
                  <div class="row clearfix">
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Amount To TopUp</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Amount"
                          v-model="amount"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <label>Your Mpesa Phone Number</label>

                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Mpesa Number eg. 2547****"
                          v-model="phoneNumber"
                          required
                        />
                      </div>
                    </div>

                    <div class="col-md-12">
                      <button class="btn btn-primary" @click="TopUpAccount">
                        <span v-if="loading">loading...</span>
                        <span v-else>Topup Account</span>
                      </button>
                    </div>

                    <div class="col-lg-12 col-md-12">
                      <h5 class="text-center mt-3">
                        How To TopUp Via Mpesa Paybill
                      </h5>

                      <p>1. Go To Mpesa Menu on your Mobile phone</p>
                      <p>2. Select Lipa na Mpesa</p>
                      <p>3. Select Pay Bill</p>
                      <p>
                        4. Enter <strong>4115783</strong> as the Business Number
                      </p>
                      <p>5. Enter <strong>2001</strong> as Account Number</p>
                      <p>6. Enter The amount eg. 1000 to pay</p>
                      <p>7. Enter your Mpesa PIN and send</p>
                      <p>
                        8. You will receive an SMS confirming the transaction
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import Cookies from "js-cookie";
import Identity from "../services/IdentitySms";

export default {
  name: "ProfilePage",
  data() {
    return {
      userId: null,
      loading: false,
      amount: "",
      phoneNumber: "",
    };
  },
  methods: {
    async TopUpAccount() {
      const toast = useToast();
      if (!this.amount || !this.phoneNumber) {
        toast.warning("Please Fill all Fields");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          amount: this.amount,
          msisdn: this.phoneNumber,
          client_id: this.userId,
        };

        const response = await Identity.post("payment/request", data, {
          headers: {
            Authorization: token,
          },
        });
        console.log("topup details", response.data);
        // if (response.status == 200) {

        // }
      } catch (err) {
        console.log(err);
        if (err.status == 500) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },

    async FetchProfile() {
      try {
        const token = Cookies.get("auth_token");
        this.loading = true;
        // const data = {
        //   client_id: this.userId,
        //   sms_units: 1,
        //   description: "",
        // };

        const response = await Identity.post(
          "client/balance",
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log("client details", response.data.message.client);
        if (response.status == 200) {
          this.userId = response.data.message.client.id;
          this.phoneNumber = response.data.message.client.msisdn;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.FetchProfile();
  },
};
</script>

<style scoped>
.mobile_menu-new {
  display: none;
}

@media screen and (max-width: 568px) {
  .mobile_menu-new {
    display: block;
    float: right;
  }
}
</style>
