<template>
  <div class="authentication">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-sm-12">
          <form class="card auth_form" @submit.prevent="loginUser">
            <div class="header">
              <img class="logo" src="/assets/images/logo.svg" alt="" />
              <h5>Log in</h5>
            </div>
            <div class="body">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Mobile Number"
                  v-model="mobileNumber"
                  :disabled="loading"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="zmdi zmdi-account-circle"></i>
                  </span>
                </div>
              </div>

              <div class="input-group mb-3">
                <input
                  type="password"
                  class="form-control"
                  placeholder="Password"
                  v-model="password"
                  :disabled="loading"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <a
                      href="forgot-password.html"
                      class="forgot"
                      title="Forgot Password"
                    >
                      <i class="zmdi zmdi-lock"></i>
                    </a>
                  </span>
                </div>
              </div>

              <button
                :disabled="loading"
                class="btn btn-primary btn-block waves-effect waves-light"
              >
                <span v-if="loading">Loading...</span>
                <span v-else>SIGN IN</span>
              </button>
            </div>

            <div class="ml-4">
              <p>
                Not Yet Registered?
                <!-- <a href="/register"> Register </a> -->
                <router-link to="/register">Register</router-link>
              </p>
            </div>
          </form>
        </div>
        <div class="col-lg-8 col-sm-12">
          <div class="card">
            <img src="/assets/images/signin.svg" alt="Sign In" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";

export default {
  name: "LoginPage",
  data() {
    return {
      mobileNumber: "",
      password: "",
    };
  },
  computed: {
    ...mapGetters("login", ["loading", "error", "loginStatus"]),
  },
  watch: {
    loginStatus(status) {
      const toast = useToast();

      if (status === "success") {
        toast.success("Login successful! Redirecting to dashboard...");
        setTimeout(() => {
          this.$router.push("/dashboard");
        }, 1000);
        //   setTimeout(() => {

        //   window.location.assign("/dashboard");
        //   window.location.href = "#/dashboard";
        // }, 1000);
      }
    },

    // if (this.error) {
    //   toast.error(this.error);
    // }
    error(newError) {
      const toast = useToast();

      if (newError) {
        toast.error(newError);
      }
    },
  },
  methods: {
    ...mapActions("login", ["LoginUser"]),
    async loginUser() {
      const toast = useToast();

      if (!this.mobileNumber || !this.password) {
        toast.warning("Mobile Number and Password are required");
        return;
      }
      this.$store.commit("login/setError", null);

      const loginData = {
        msisdn: this.mobileNumber,
        password: this.password,
      };

      await this.LoginUser(loginData);
    },
  },
};
</script>
