import LoginService from "../../services/Auth";
import Cookies from "js-cookie";

const state = {
  loginStatus: "",
  user: null,
  loading: false,
  error: null,
};

const getters = {
  loginStatus: (state) => state.loginStatus,
  user: (state) => state.user,
  loading: (state) => state.loading,
  error: (state) => state.error,
};

const actions = {
  async LoginUser({ commit }, loginData) {
    commit("setLoading", true);
    commit("setError", null);

    try {
      const response = await LoginService.post("/user/login", loginData);
      console.log(response);

      const { token, user } = response.data.message;
      //   console.log(token, user);
      //   console.log("User:", user);
      // console.log('cbl', client_billing)

      if (response.status === 202) {
        Cookies.set("auth_token", token, { expires: 1 });
        localStorage.setItem("user_role", user.role_id);
        // localStorage.setItem("user_full_name", user.full_name);
        localStorage.setItem("user_id", user.id);

        // localStorage.setItem("balance", client_billing.balance);

        commit("setUser", user);
        commit("setLoginStatus", "success");
        commit("setError", null);
      } else {
        commit("setError", "Unexpected status code received.");
      }
    } catch (error) {
      //   console.log(error);
      commit("setLoginStatus", "failed");

      if (error.response) {
        const { status, data } = error.response;

        switch (status) {
          case 400:
            commit(
              "setError",
              data.message || "Bad Request. Please check the input."
            );
            break;
          case 422:
            commit(
              "setError",
              data.message ||
                "Unprocessable Entity. Please check the form data."
            );
            break;
          case 500:
            commit(
              "setError",
              data.message || "Internal Server Error. Please try again later."
            );
            break;
          default:
            commit(
              "setError",
              data.message || "An error occurred. Please try again."
            );
        }
      } else {
        commit("setError", "Network error. Please check your connection.");
      }
    } finally {
      commit("setLoading", false);
    }
  },
  LogoutUser({ commit }) {
    Cookies.remove("auth_token");
    localStorage.removeItem("user_role");
    localStorage.removeItem("dashboardReloaded");

    // localStorage.removeItem("user_full_name");
    localStorage.removeItem("user_id");

    commit("setUser", null);
    commit("setLoginStatus", "");
    commit("setError", "");
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
    console.log(user);
  },

  setLoading(state, status) {
    state.loading = status;
  },

  setLoginStatus(state, status) {
    state.loginStatus = status;
  },

  setError(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
