<template>
  <div>
    <section class="content">
      <div class="body_scroll">
        <div class="block-header">
          <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
              <h2>Promotional Sms</h2>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href=" /"><i class="zmdi zmdi-home"></i> Admin</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="javascript:void(0);">Promotional</a>
                </li>
                <li class="breadcrumb-item active">Sms</li>
              </ul>
              <!-- <button
                class="btn btn-primary btn-icon mobile_menu"
                type="button"
              >
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button> -->
              <button
                class="btn btn-primary btn-icon mobile_menu-new"
                type="button"
                @click="$root.toggleSidebar"
              >
                <!-- @click="$emit('toggle-sidebar')" -->
                <!-- @click ="$root.toggleSidebar" -->
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row clearfix">
            <div class="col-lg-12">
              <div class="card">
                <div class="header">
                  <button
                    type="button"
                    class="btn btn-success waves-effect m-r-20"
                    @click="handleCsvDownload"
                  >
                    Download
                  </button>
                </div>

                <section class="d-flex flex-wrap justify-content-between mb-4">
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label for="dateRangeSelect">Select Date Range</label>
                      <select
                        class="form-control"
                        id="dateRangeSelect"
                        v-model="selectedOption"
                        @change="handleDateRangeChange"
                      >
                        <option value="choose">Choose Custom</option>
                        <option value="all">All SMS</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="last7">Last 7 Days</option>
                        <option value="lastMonth">Last Month</option>
                        <option value="thisMonth">This Month</option>
                        <option value="custom">Custom Range</option>
                      </select>
                    </div>

                    <div v-if="showCustomRange">
                      <div class="form-group">
                        <label for="startDate">Start Date</label>
                        <input
                          type="date"
                          class="form-control"
                          id="startDate"
                          v-model="startDate"
                          @change="handleCustomDateChange"
                        />
                      </div>
                      <div class="form-group">
                        <label for="endDate">End Date</label>
                        <input
                          type="date"
                          class="form-control"
                          id="endDate"
                          v-model="endDate"
                          @change="handleCustomDateChange"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-lg-4 d-flex justify-content-end">
                    <div class="form-group w-100">
                      <label for="searchInput">Search</label>
                      <input
                        type="text"
                        class="form-control"
                        id="searchInput"
                        placeholder="Search..."
                        v-model="searchQuery"
                      />
                    </div>
                  </div>
                </section>

                <div class="body">
                  <div class="table-responsive">
                    <table
                      class="table table-bordered table-striped table-hover"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Date</th>
                          <th>Mobile Number</th>
                          <th>Sender ID</th>
                          <th>Status</th>
                          <th>Network</th>
                          <th>Cost</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-if="loading">
                          <td colspan="7">Loading...</td>
                        </tr>

                        <tr v-else-if="paginatedSms.length === 0">
                          <td colspan="7">No SMS Found</td>
                        </tr>

                        <tr
                          v-else
                          v-for="(sms, index) in paginatedSms"
                          :key="sms.id"
                          @click="openModal(sms.message)"
                        >
                          <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
                          <td>{{ formatDate(sms.created) }}</td>
                          <td>{{ sms.msisdn || "N/A" }}</td>
                          <td>{{ sms.sender_id || "N/A" }}</td>
                          <td>{{ sms.status_description || "N/A" }}</td>
                          <td>{{ sms.carrier_name || "N/A" }}</td>
                          <td>{{ sms.sms_cost || 0 }}</td>
                        </tr>
                      </tbody>

                      <tfoot>
                        <tr>
                          <th>#</th>
                          <th>Date</th>
                          <th>Mobile Number</th>
                          <th>Sender ID</th>
                          <th>Status</th>
                          <th>Network</th>
                          <th>Cost</th>
                        </tr>
                      </tfoot>
                    </table>

                    <div v-if="showModal" class="modal-overlay">
                      <div class="modal-content">
                        <h4 style="font-weight: 600">Message</h4>
                        <strong style="font-weight: bold; font-style: italic">{{
                          selectedMessage
                        }}</strong>
                        <button @click="closeModal" class="btn-close-modal">
                          Close
                        </button>
                      </div>
                    </div>

                    <!-- Pagination -->
                    <nav aria-label="Page navigation">
                      <ul class="pagination">
                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(1)"
                            >First</a
                          >
                        </li>

                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(currentPage - 1)"
                            >Previous</a
                          >
                        </li>

                        <li
                          v-for="page in limitedPageRange"
                          :key="page"
                          class="page-item"
                          :class="{ active: currentPage === page }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(page)"
                            >{{ page }}</a
                          >
                        </li>

                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === totalPages }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(currentPage + 1)"
                            >Next</a
                          >
                        </li>

                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === totalPages }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(totalPages)"
                            >Last</a
                          >
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { format, startOfMonth, endOfMonth, subDays, subMonths } from "date-fns";
import SMSService from "../services/smsService";
import Cookies from "js-cookie";

export default {
  name: "SendSmsPage",
  data() {
    return {
      promotionalSms: [],
      filteredSms: [],
      loading: false,
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      showModal: false,
      selectedMessage: "",
      selectedOption: "all",
      showCustomRange: false,
      startDate: "",
      endDate: "",
      searchQuery: "",
    };
  },
  computed: {
    paginatedSms() {
      // if (!Array.isArray(this.filteredSms)) {
      //   return [];
      // }

      // const start = (this.currentPage - 1) * this.perPage;
      // const end = start + this.perPage;
      // return this.promotionalSms.slice(start, end);
      // return this.filteredSms.slice(start, end);
      return this.filteredSms;
    },
    limitedPageRange() {
      const start = Math.max(1, this.currentPage - 5);
      const end = Math.min(this.totalPages, start + 9);
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
  },
  methods: {
    openModal(message) {
      this.selectedMessage = message;
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
      this.selectedMessage = "";
    },

    handleDateRangeChange() {
      const today = new Date();

      switch (this.selectedOption) {
        case "all": {
          this.startDate = "2018-02-07";
          this.endDate = format(today, "yyyy-MM-dd");
          this.showCustomRange = false;
          break;
        }

        case "today": {
          const formattedToday = format(today, "yyyy-MM-dd");
          this.startDate = formattedToday;
          this.endDate = formattedToday;
          this.showCustomRange = false;
          break;
        }

        case "yesterday": {
          const yesterday = subDays(today, 1);
          const formattedYesterday = format(yesterday, "yyyy-MM-dd");
          this.startDate = formattedYesterday;
          this.endDate = formattedYesterday;
          this.showCustomRange = false;
          break;
        }

        case "last7": {
          const last7Days = subDays(today, 7);
          this.startDate = format(last7Days, "yyyy-MM-dd");
          this.endDate = format(today, "yyyy-MM-dd");
          this.showCustomRange = false;
          break;
        }

        case "lastMonth": {
          const lastMonth = subMonths(today, 1);
          this.startDate = format(startOfMonth(lastMonth), "yyyy-MM-dd");
          this.endDate = format(endOfMonth(lastMonth), "yyyy-MM-dd");
          this.showCustomRange = false;
          break;
        }

        case "thisMonth": {
          this.startDate = format(startOfMonth(today), "yyyy-MM-dd");
          this.endDate = format(today, "yyyy-MM-dd");
          this.showCustomRange = false;
          break;
        }

        case "custom": {
          this.showCustomRange = true;
          break;
        }

        default: {
          this.startDate = "";
          this.endDate = "";
          this.showCustomRange = false;
        }
      }
      console.log("Selected Option:", this.selectedOption);
      console.log("Start Date:", this.startDate);
      console.log("End Date:", this.endDate);
      this.fetchPromotional();
    },

    handleCustomDateChange() {
      console.log("Custom Start Date:", this.startDate);
      console.log("Custom End Date:", this.endDate);

      this.fetchPromotional();
    },

    async fetchPromotional() {
      try {
        const token = Cookies.get("auth_token");
        this.loading = true;

        const response = await SMSService.post(
          `message/all/promotional`,
          {
            page: this.currentPage,
            per_page: this.perPage,
            start_date: this.startDate,
            end_date: this.endDate,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log(response);

        if (response.status === 200) {
          this.promotionalSms = response.data.data || [];
          this.filteredSms = this.promotionalSms;

          this.totalPages = response.data.last_page || 1;
        }
      } catch (err) {
        console.log(err);
        this.promotionalSms = [];
        this.filteredSms = [];
      } finally {
        this.loading = false;
      }
    },

    async fetchpromotionalSearch() {
      try {
        const token = Cookies.get("auth_token");
        this.loading = true;
        const data = {
          page: this.currentPage,
          per_page: this.perPage,
          // msisdn: this.searchQuery,
        };
        console.log(data);

        const response = await SMSService.post(
          `message/all/promotional?msisdn=${this.searchQuery}`,
          data,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log(response);

        if (response.status === 200) {
          this.filteredSms = response.data.data || [];
          this.totalPages = response.data.last_page || 1;
        }
      } catch (err) {
        console.log(err);
        this.filteredSms = [];
      } finally {
        this.loading = false;
      }
    },

    async handleSearch() {
      if (this.searchQuery.trim() === "") {
        this.filteredSms = this.promotionalSms;
      } else {
        await this.fetchpromotionalSearch();
      }
    },

    // async changePage(page) {
    //   if (page >= 1 && page <= this.totalPages) {
    //     this.currentPage = page;
    //     await this.fetchPromotional();
    //   }
    // },

    async changePage(page) {
      console.log(page);
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;

        try {
          if (this.searchQuery.trim() === "") {
            await this.fetchPromotional();
          } else {
            await this.fetchpromotionalSearch();
          }

          this.filteredSms = this.promotionalSms;
        } catch (error) {
          console.error("Error while changing page:", error);
          this.filteredSms = [];
        }
      }
    },

    formatDate(dateStr) {
      const date = new Date(dateStr);
      return format(date, "do MMM yyyy h:mm a");
    },

    async handleCsvDownload() {
      await this.downloadCsv();
    },

    async downloadCsv() {
      console.log("Download button clicked");
      const allData = await this.fetchAllPromotionalForDownload();
      console.log("Fetched data for CSV:", allData);

      if (allData && allData.length > 0) {
        const csvContent = this.convertToCsv(allData);
        // console.log("CSV Content:", csvContent);

        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "transactional_sms.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.log("No data available for CSV export.");
      }
    },

    async fetchAllPromotionalForDownload() {
      try {
        const token = Cookies.get("auth_token");
        this.loading = true;

        const response = await SMSService.post(
          `message/all/promotional`,
          {
            page: 1,
            per_page: 50000,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        console.log("Fetched all promotional data for CSV:", response);

        if (response.status === 200) {
          return response.data.data;
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        this.loading = false;
      }
    },

    convertToCsv(data) {
      const headers = [
        "Date",
        "Mobile Number",
        "Sender ID",
        "Status",
        "Network",
        "Cost",
        "Message",
      ];
      const csvRows = [
        headers.join(","),
        ...data.map((item) =>
          [
            this.formatDate(item.created),
            item.msisdn || "N/A",
            item.sender_id || "N/A",
            item.status_description || "N/A",
            item.carrier_name || "N/A",
            item.sms_cost || 0,
            `"${item.message.replace(/"/g, '""')}"`,
          ].join(",")
        ),
      ];
      return csvRows.join("\n");
    },
  },
  watch: {
    searchQuery() {
      this.handleSearch();
    },
  },
  mounted() {
    this.fetchPromotional();
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  width: 200px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-close-modal {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-close-modal:hover {
  background-color: #0056b3;
}

.mobile_menu-new {
  display: none;
}

@media screen and (max-width: 568px) {
  .mobile_menu-new {
    display: block;
    float: right;
  }
}
</style>
