<template>
  <div>
    <section class="content">
      <div class="body_scroll">
        <div class="block-header">
          <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
              <h2>SMPP ACCOUNTS</h2>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/"><i class="zmdi zmdi-home"></i> Admin</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="javascript:void(0);">Smpp</a>
                </li>
                <li class="breadcrumb-item active">Accounts</li>
              </ul>
              <button
                class="btn btn-primary btn-icon mobile_menu-new"
                type="button"
                @click="$root.toggleSidebar"
              >
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
              <button
                class="btn btn-primary btn-icon float-right right_icon_toggle_btn"
                type="button"
              >
                <i class="zmdi zmdi-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row clearfix">
            <div class="col-lg-12">
              <div class="card">
                <div class="header">
                  <button
                    type="button"
                    class="btn btn-default waves-effect m-r-20"
                    data-toggle="modal"
                    data-target="#largeModal"
                  >
                    CREATE NEW SMPP Service Account
                  </button>
                </div>

                <div
                  class="modal fade"
                  id="largeModal"
                  tabindex="-1"
                  role="dialog"
                >
                  <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="title" id="largeModalLabel">Add User</h4>
                      </div>
                      <div class="modal-body">
                        <div class="body">
                          <form
                            id="form_validation"
                            @submit.prevent="CreateUser"
                          >
                            <div class="form-group form-float">
                              <label class="fw-bold">Full Name</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Username"
                                name="group"
                                required
                                v-model="username"
                              />
                            </div>

                            <div class="form-group form-float">
                              <input
                                type="email"
                                class="form-control"
                                placeholder="Email Address"
                                name="Email"
                                required
                                v-model="email"
                              />
                            </div>
                            <div class="form-group form-float">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Mobile Number eg. 0784******"
                                name="mobile-number"
                                required
                                v-model="mobileNumber"
                              />
                            </div>
                            <div class="form-group form-float">
                              <label class="fw-bold">User Role Name</label>

                              <select
                                class="form-control"
                                required
                                @change="handleRoleChange"
                                v-if="userRoles && userRoles.length > 0"
                                v-model="selectedRoleId"
                              >
                                <option disabled value="">
                                  Choose User Role
                                </option>
                                <option
                                  v-for="role in userRoles"
                                  :key="role.id"
                                  :value="role.id"
                                >
                                  {{ role.name }}
                                </option>
                              </select>
                              <p v-else>No roles available</p>
                            </div>

                            <button
                              class="btn btn-raised btn-primary waves-effect"
                              type="submit"
                            >
                              <span v-if="loading">Loading...</span>
                              <span v-else>Create Contact Group</span>
                            </button>
                          </form>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-danger waves-effect"
                          data-dismiss="modal"
                        >
                          CLOSE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="modal fade"
                  id="deleteUserModal"
                  tabindex="-1"
                  role="dialog"
                >
                  <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="title" id="deleteUserModal">
                          Delete User

                          {{
                            selectedUser && selectedUser.full_name
                              ? ` - ${selectedUser.full_name}`
                              : ""
                          }}
                        </h4>
                      </div>
                      <div class="modal-body">
                        <div class="body">
                          <p>Are you sure?</p>

                          <button
                            class="btn btn-raised btn-primary waves-effect"
                            type="submit"
                            @click="deleteUser"
                          >
                            <span v-if="loading">Loading...</span>
                            <span v-else>OK</span>
                          </button>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-danger waves-effect"
                          data-dismiss="modal"
                        >
                          CLOSE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="modal fade"
                  id="updateUserRoleModal"
                  tabindex="-1"
                  role="dialog"
                >
                  <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="title" id="updateUserRoleModal">
                          {{
                            selectedUser && selectedUser.user_status === 1
                              ? "Deactivate Contact"
                              : "Activate Contact"
                          }}

                          {{
                            selectedUser && selectedUser.full_name
                              ? ` - ${selectedUser.full_name}`
                              : ""
                          }}
                        </h4>
                      </div>
                      <div class="modal-body">
                        <div class="body">
                          <p>Are you sure?</p>

                          <button
                            class="btn btn-raised btn-primary waves-effect"
                            type="submit"
                            @click="updateUser"
                          >
                            <span v-if="loading">Loading...</span>
                            <span v-else>OK</span>
                          </button>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-danger waves-effect"
                          data-dismiss="modal"
                        >
                          CLOSE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="body">
                  <div class="table-responsive">
                    <table
                      class="table table-bordered table-striped table-hover js-basic-example dataTable"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Username</th>
                          <th>Account Type</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="loading">
                          <td colspan="6">Loading...</td>
                        </tr>

                        <tr v-else-if="users.length === 0 || users == null">
                          <td colspan="6" class="text-center">No user found</td>
                        </tr>

                        <tr
                          v-else
                          v-for="(user, index) in users"
                          :key="user.id"
                        >
                          <td>{{ index + 1 }}</td>
                          <td>{{ user.full_name }}</td>
                          <td>
                            {{ user.msisdn }}
                            <p style="font-size: 12px; font-weight: bold">
                              {{ user.email }}
                            </p>
                          </td>
                          <td>{{ user.role_name }}</td>
                          <td>
                            <span
                              :class="{
                                'text-success': user.user_status === 1,
                                'text-danger': user.user_status !== 1,
                              }"
                            >
                              {{
                                user.user_status === 1 ? "Active" : "Inactive"
                              }}
                            </span>
                          </td>
                          <td>
                            <button
                              v-if="user.user_status === 1"
                              class="btn btn-warning"
                              data-toggle="modal"
                              data-target="#updateUserRoleModal"
                              @click="setSelectedUser(user)"
                            >
                              Deactivate
                            </button>
                            <button
                              v-else
                              class="btn btn-success"
                              data-toggle="modal"
                              data-target="#updateUserRoleModal"
                              @click="setSelectedUser(user)"
                            >
                              Activate
                            </button>

                            <button
                              class="btn btn-danger"
                              data-toggle="modal"
                              data-target="#deleteUserModal"
                              @click="setSelectedUser(user)"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>#</th>
                          <th>Username</th>
                          <th>Account Type</th>
                          <th>Status</th>
                          <th>Actions</th>
                          >
                        </tr>
                      </tfoot>
                    </table>

                    <!-- <nav aria-label="Page navigation">
                        <ul class="pagination">
                          <li
                            class="page-item"
                            :class="{ disabled: currentPage === 1 }"
                          >
                            <a
                              class="page-link"
                              href="#"
                              @click.prevent="changePage(1)"
                              >First</a
                            >
                          </li>
                          <li
                            class="page-item"
                            :class="{ disabled: currentPage === 1 }"
                          >
                            <a
                              class="page-link"
                              href="#"
                              @click.prevent="changePage(currentPage - 1)"
                              >Previous</a
                            >
                          </li>
                          <li
                            v-for="page in limitedPageRange"
                            :key="page"
                            class="page-item"
                            :class="{ active: currentPage === page }"
                          >
                            <a
                              class="page-link"
                              href="#"
                              @click.prevent="changePage(page)"
                              >{{ page }}</a
                            >
                          </li>
                          <li
                            class="page-item"
                            :class="{ disabled: currentPage === totalPages }"
                          >
                            <a
                              class="page-link"
                              href="#"
                              @click.prevent="changePage(currentPage + 1)"
                              >Next</a
                            >
                          </li>
                          <li
                            class="page-item"
                            :class="{ disabled: currentPage === totalPages }"
                          >
                            <a
                              class="page-link"
                              href="#"
                              @click.prevent="changePage(totalPages)"
                              >Last</a
                            >
                          </li>
                        </ul>
                      </nav> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import Smpp from "../services/Smpp";
import { useToast } from "vue-toastification";

export default {
  name: "SmppAccountPage",
  data() {
    return {
      users: [],
      userRoles: [],
      username: "",
      email: "",
      mobileNumber: "",
      selectedRoleId: "",
      loading: false,
      // currentPage: 1,
      // perPage: 10,
      // totalPages: 1,
      selectedUser: null,
    };
  },
  mounted() {
    this.fetchUsers();
    this.fetchUserRoles();
  },
  // computed: {
  //   limitedPageRange() {
  //     const start = Math.max(1, this.currentPage - 5);
  //     const end = Math.min(this.totalPages, start + 9);
  //     return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  //   },
  // },
  methods: {
    setSelectedUser(user) {
      this.selectedUser = { ...user };
    },
    async fetchUsers() {
      try {
        const token = Cookies.get("auth_token");
        this.loading = true;
        const response = await Smpp.post(
          "user/all",
          {},

          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log(response);
        if (response.status === 200) {
          this.users = response.data.data || [];
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        this.users = [];
      } finally {
        this.loading = false;
      }
    },

    async fetchUserRoles() {
      const toast = useToast();
      try {
        const token = Cookies.get("auth_token");
        this.loading = true;
        const response = await Smpp.post(
          "client/user/role",
          {},

          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log("user roles", response.data.message);
        if (response.status === 200) {
          this.userRoles = response.data.message || [];
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        if (error.status == 401) {
          toast.info("Token Expired");
          this.$router.push("/");
        }
        this.userRoles = [];
      } finally {
        this.loading = false;
      }
    },
    handleRoleChange() {
      console.log("Selected Role ID:", this.selectedRoleId);
    },

    //   async changePage(page) {
    //     if (page >= 1 && page <= this.totalPages) {
    //       this.currentPage = page;
    //       await this.fetchUsers();
    //     }
    //   },

    async CreateUser() {
      const toast = useToast();

      if (
        !this.username ||
        !this.email ||
        !this.mobileNumber ||
        !this.selectedRoleId
      ) {
        toast.warning("Please fill all required fields");
        return;
      }
      try {
        const token = Cookies.get("auth_token");

        const data = {
          email: this.email,
          fullname: this.username,
          msisdn: this.mobileNumber,
          role_id: this.selectedRoleId,
        };

        this.loading = true;
        const response = await Smpp.post("client/user/create", data, {
          headers: {
            Authorization: token,
          },
        });

        console.log("created user", response);
        if (response.status == 201) {
          toast.success(response.data.message);
          this.fetchUsers();
        }
      } catch (err) {
        console.log(err);
        if (err.status == 500) {
          toast.error(err.response.data.message || "Invalid Inputs");
        }
      } finally {
        this.loading = false;
      }
    },

    async updateUser() {
      const toast = useToast();
      console.log("users", this.selectedUser.id);

      if (!this.selectedUser || !this.selectedUser.id) {
        toast.error("User ID is missing. Cannot update user.");
        return;
      }

      try {
        const token = Cookies.get("auth_token");
        this.loading = true;
        const data = {
          user_id: this.selectedUser.id,
          status: this.selectedUser.user_status === 1 ? 0 : 1,
        };

        const res = await Smpp.post("client/user/update", data, {
          headers: {
            Authorization: token,
          },
        });

        console.log(res);

        if (res.status == 201) {
          toast.success(res.data.message);
          this.fetchUsers();
        }
      } catch (err) {
        console.log(err);
        if (err.status == 422) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },

    async deleteUser() {
      const toast = useToast();
      console.log("users", this.selectedUser.id);

      if (!this.selectedUser || !this.selectedUser.id) {
        toast.error("User ID is missing. Cannot update user.");
        return;
      }

      try {
        const token = Cookies.get("auth_token");
        this.loading = true;
        const data = {
          user_id: this.selectedUser.id,
          status: this.selectedUser.user_status === 1 ? 0 : 1,
        };

        const res = await Smpp.post("client/user/update", data, {
          headers: {
            Authorization: token,
          },
        });

        console.log(res);

        if (res.status == 201) {
          toast.success(res.data.message);
          this.fetchUsers();
        }
      } catch (err) {
        console.log(err);
        if (err.status == 422) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.mobile_menu-new {
  display: none;
}
@media screen and (max-width: 568px) {
  .mobile_menu-new {
    display: block;
    float: right;
  }
}
.text-success {
  color: green;
}
.text-danger {
  color: red;
}
</style>
