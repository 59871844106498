<template>
  <section class="content modal-overlay">
    <div class="body_scroll modal-content">
      <div class="block-header">
        <div class="row">
          <div class="col-lg-7 col-md-6 col-sm-12">
            <h2>Send SMS</h2>
            <!-- <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/"><i class="zmdi zmdi-home"></i> Admin</a>
                </li>

                <li class="breadcrumb-item">
                  <a href="javascript:void(0);">Send</a>
                </li>
                <li class="breadcrumb-item active">Sms</li>
              </ul> -->
            <!-- <button
                class="btn btn-primary btn-icon mobile_menu"
                type="button"
              >
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button> -->
          </div>

          <div class="col-lg-5 col-md-6 col-sm-12">
            <button
              class="btn btn-primary btn-icon float-right right_icon_toggle_btn"
              type="button"
              @click="$emit('close')"
            >
              <!-- <i class="zmdi zmdi-arrow-right"></i> -->
              <i class="zmdi zmdi-close"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- <div class="modal-body-scroll"> -->

      <div class="container-fluid">
        <div class="row clearfix">
          <div class="col-sm-12">
            <div class="card">
              <div class="body">
                <ul class="nav nav-tabs p-0 mb-3 nav-tabs-warning">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      data-toggle="tab"
                      href="#profile_only_icon_title"
                      >Contacts <i class="zmdi zmdi-account"></i
                    ></a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      href="#messages_only_icon_title"
                      >Uploads <i class="zmdi zmdi-email"></i
                    ></a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      href="#settings_only_icon_title"
                      >Lists <i class="zmdi zmdi-settings"></i
                    ></a>
                  </li>
                </ul>
                <!-- Tab panes -->
                <div class="tab-content">
                  <div
                    role="tabpanel"
                    class="tab-pane in active"
                    id="profile_only_icon_title"
                  >
                    <div class="card">
                      <div class="col-12">
                        <div class="alert alert-warning" role="alert">
                          <strong
                            >Separate multiple contacts with comma (,) eng
                            07200000000,07200000001,07200000003</strong
                          >
                        </div>
                      </div>
                      <form class="body" @submit.prevent="sendSms">
                        <div class="row clearfix">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="0712345678"
                                v-model="phoneNumber"
                              />
                            </div>
                          </div>
                        </div>
                        <h2 class="card-inside-title">Sender Id</h2>
                        <div class="row clearfix">
                          <div class="col-sm-12">
                            <select
                              class="form-control show-tick"
                              v-model="selectedSender"
                              v-if="SenderID.length > 0"
                              @change="logSenderID"
                            >
                              <option disabled value="">
                                Select Sender ID
                              </option>

                              <option
                                v-for="sender in SenderID"
                                :key="sender.id"
                                :value="sender.sender_id"
                              >
                                {{ sender.sender_id }}
                                {{ sender.carrier_name }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <h2 class="card-inside-title">Message</h2>
                        <div class="row clearfix">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <div class="form-line">
                                <textarea
                                  rows="4"
                                  class="form-control no-resize"
                                  placeholder="Please type what you want..."
                                  v-model="message"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="checkbox">
                          <input
                            id="remember_me"
                            type="checkbox"
                            v-model="isScheduled"
                          />
                          <label for="remember_me"> Schedule </label>
                        </div>

                        <!-- Div containing the schedule options, conditionally rendered -->
                        <div v-if="isScheduled" class="modal-body-scroll">
                          <div class="row clearfix">
                            <div class="col-sm-12">
                              <h6>Choose when and how to Send this message</h6>
                              <select
                                class="form-control show-tick"
                                v-model="scheduleType"
                                @change="handleScheduleChange"
                              >
                                <option disabled value="">
                                  Choose Frequency
                                </option>
                                <option value="NO_REPEAT">
                                  SEND ONLY ONCE
                                </option>
                                <option value="EVERY_DAY">
                                  REPEAT EVERYDAY
                                </option>
                                <option value="EVERY_WEEK">
                                  REPEAT EVERY WEEK
                                </option>
                                <option value="EVERY_MONTH">
                                  REPEAT EVERY MONTH
                                </option>
                              </select>
                            </div>
                          </div>
                          <br />
                          <br />

                          <div class="row clearfix">
                            <div class="col-sm-6 mt-lg-0">
                              <h6>Time</h6>
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"
                                    ><i class="zmdi zmdi-time"></i
                                  ></span>
                                </div>
                                <input
                                  type="time"
                                  class="form-control timepicker"
                                  placeholder="Please choose a time..."
                                  v-model="scheduleTime"
                                />
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <h6>Start Date</h6>
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"
                                    ><i class="zmdi zmdi-calendar"></i
                                  ></span>
                                </div>
                                <input
                                  type="date"
                                  class="form-control datetimepicker"
                                  placeholder="Please choose date & time..."
                                  v-model="startDate"
                                />
                              </div>
                            </div>

                            <div
                              class="col-sm-6 mt-3"
                              v-if="scheduleType !== 'NO_REPEAT'"
                            >
                              <h6>End Date</h6>
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"
                                    ><i class="zmdi zmdi-calendar"></i
                                  ></span>
                                </div>
                                <input
                                  type="date"
                                  class="form-control datetimepicker"
                                  placeholder="Please choose date & time..."
                                  v-model="endDate"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <button
                          class="btn btn-raised btn-primary waves-effect"
                          type="submit"
                        >
                          <span v-if="loading">Loading...</span>
                          <span v-else>SUBMIT</span>
                        </button>
                        <button
                          class="btn btn-raised btn-warning waves-effect"
                          type="button"
                          @click="clearFields"
                        >
                          CANCEL
                        </button>
                      </form>
                    </div>
                  </div>
                  <div
                    role="tabpanel"
                    class="tab-pane"
                    id="messages_only_icon_title"
                  >
                    <div class="col-12">
                      <div class="alert alert-warning" role="alert">
                        <strong
                          >Separate multiple contacts with comma (,) eng
                          07200000000,07200000001,07200000003</strong
                        >
                      </div>
                    </div>

                    <form
                      class="col-lg-12 col-md-12"
                      enctype="multipart/form-data"
                      @submit.prevent="uploadContact"
                    >
                      <div class="card">
                        <div class="header">
                          <h2>Default</h2>
                        </div>
                        <div class="body">
                          <input
                            type="file"
                            class="dropify"
                            @change="handleFileUpload"
                          />
                        </div>
                        <div class="body">
                          <h2 class="card-inside-title">Campaign name</h2>
                          <div class="row clearfix">
                            <div class="col-sm-12">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder=""
                                  v-model="campaign_name"
                                />
                              </div>
                            </div>
                          </div>
                          <h2 class="card-inside-title">Sender Id</h2>
                          <div class="row clearfix">
                            <div class="col-sm-12">
                              <select
                                class="form-control show-tick"
                                v-model="selectedSender"
                                v-if="SenderID.length > 0"
                                @change="logSenderID"
                              >
                                <option disabled value="">
                                  Select Sender ID
                                </option>

                                <option
                                  v-for="sender in SenderID"
                                  :key="sender.id"
                                  :value="sender.sender_id"
                                >
                                  {{ sender.sender_id }}
                                  {{ sender.carrier_name }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <h2 class="card-inside-title">Message</h2>
                          <div class="row clearfix">
                            <div class="col-sm-12">
                              <div class="form-group">
                                <div class="form-line">
                                  <textarea
                                    rows="4"
                                    class="form-control no-resize"
                                    placeholder="Please type what you want..."
                                    v-model="message"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="checkbox">
                            <input
                              id="remember_me"
                              type="checkbox"
                              v-model="isScheduled"
                            />
                            <label for="remember_me"> Schedule </label>
                          </div>

                          <!-- Div containing the schedule options, conditionally rendered -->
                          <div v-if="isScheduled">
                            <div class="row clearfix">
                              <div class="col-sm-12">
                                <h6>
                                  Choose when and how to Send this message
                                </h6>
                                <select
                                  class="form-control show-tick"
                                  v-model="scheduleType"
                                  @change="handleScheduleChange"
                                >
                                  <option disabled value="">
                                    Choose Frequency
                                  </option>
                                  <option value="NO_REPEAT">
                                    SEND ONLY ONCE
                                  </option>
                                  <option value="EVERY_DAY">
                                    REPEAT EVERYDAY
                                  </option>
                                  <option value="EVERY_WEEK">
                                    REPEAT EVERY WEEK
                                  </option>
                                  <option value="EVERY_MONTH">
                                    REPEAT EVERY MONTH
                                  </option>
                                </select>
                              </div>
                            </div>
                            <br />
                            <br />

                            <div class="row clearfix">
                              <div class="col-sm-6 mt-lg-4">
                                <h6>Time</h6>
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text"
                                      ><i class="zmdi zmdi-time"></i
                                    ></span>
                                  </div>
                                  <input
                                    type="time"
                                    class="form-control timepicker"
                                    placeholder="Please choose a time..."
                                    v-model="scheduleTime"
                                  />
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <h6>Start Date</h6>
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text"
                                      ><i class="zmdi zmdi-calendar"></i
                                    ></span>
                                  </div>
                                  <input
                                    type="date"
                                    class="form-control datetimepicker"
                                    placeholder="Please choose date & time..."
                                    v-model="startDate"
                                  />
                                </div>
                              </div>

                              <div
                                class="col-sm-6"
                                v-if="scheduleType !== 'NO_REPEAT'"
                              >
                                <h6>End Date</h6>
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text"
                                      ><i class="zmdi zmdi-calendar"></i
                                    ></span>
                                  </div>
                                  <input
                                    type="date"
                                    class="form-control datetimepicker"
                                    placeholder="Please choose date & time..."
                                    v-model="endDate"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            class="btn btn-raised btn-primary waves-effect"
                            type="submit"
                          >
                            <span v-if="loading">Loading...</span>
                            <span v-else>SUBMIT</span>
                          </button>
                          <button
                            class="btn btn-raised btn-warning waves-effect"
                            type="button"
                            @click="clearFields"
                          >
                            CANCEL
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    role="tabpanel"
                    class="tab-pane"
                    id="settings_only_icon_title"
                  >
                    <form class="body" @submit.prevent="CreateList">
                      <h2 class="card-inside-title">Contact Group</h2>
                      <div class="row clearfix">
                        <div class="col-sm-12">
                          <select
                            class="form-control"
                            required
                            v-model="selectedGroupName"
                            v-if="contactgroup && contactgroup.length > 0"
                            @change="logGroupName"
                          >
                            <option disabled value="">
                              Select Contact Group
                            </option>
                            <option
                              v-for="group in contactgroup"
                              :key="group.id"
                              :value="group.id"
                            >
                              {{ group.name }}, {{ group.contacts }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <h2 class="card-inside-title">Campaign name</h2>
                      <div class="row clearfix">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              v-model="campaign_name"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <h2 class="card-inside-title">Sender Id</h2>
                      <div class="row clearfix">
                        <div class="col-sm-12">
                          <select
                            class="form-control show-tick"
                            v-model="selectedSender"
                            v-if="SenderID.length > 0"
                            @change="logSenderID"
                            required
                          >
                            <option disabled value="">Select Sender ID</option>

                            <option
                              v-for="sender in SenderID"
                              :key="sender.id"
                              :value="sender.sender_id"
                            >
                              {{ sender.sender_id }}
                              {{ sender.carrier_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <h2 class="card-inside-title">Message</h2>
                      <div class="row clearfix">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <div class="form-line">
                              <textarea
                                rows="4"
                                class="form-control no-resize"
                                placeholder="This message includes the OPT out option STOP *456*9*5#"
                                required
                                v-model="message"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="checkbox">
                        <input
                          id="remember_me"
                          type="checkbox"
                          v-model="isScheduled"
                        />
                        <label for="remember_me"> Schedule </label>
                      </div>

                      <!-- Div containing the schedule options, conditionally rendered -->
                      <div v-if="isScheduled">
                        <div class="row clearfix">
                          <div class="col-sm-12">
                            <h6>Choose when and how to Send this message</h6>
                            <select
                              class="form-control show-tick"
                              v-model="scheduleType"
                              @change="handleScheduleChange"
                            >
                              <option disabled value="">
                                Choose Frequency
                              </option>
                              <option value="NO_REPEAT">SEND ONLY ONCE</option>
                              <option value="EVERY_DAY">REPEAT EVERYDAY</option>
                              <option value="EVERY_WEEK">
                                REPEAT EVERY WEEK
                              </option>
                              <option value="EVERY_MONTH">
                                REPEAT EVERY MONTH
                              </option>
                            </select>
                          </div>
                        </div>
                        <br />
                        <br />

                        <div class="row clearfix">
                          <div class="col-sm-6 mt-lg-4">
                            <h6>Time</h6>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text"
                                  ><i class="zmdi zmdi-time"></i
                                ></span>
                              </div>
                              <input
                                type="time"
                                class="form-control timepicker"
                                placeholder="Please choose a time..."
                                v-model="scheduleTime"
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <h6>Start Date</h6>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text"
                                  ><i class="zmdi zmdi-calendar"></i
                                ></span>
                              </div>
                              <input
                                type="date"
                                class="form-control datetimepicker"
                                placeholder="Please choose date & time..."
                                v-model="startDate"
                              />
                            </div>
                          </div>

                          <div
                            class="col-sm-6"
                            v-if="scheduleType !== 'NO_REPEAT'"
                          >
                            <h6>End Date</h6>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text"
                                  ><i class="zmdi zmdi-calendar"></i
                                ></span>
                              </div>
                              <input
                                type="date"
                                class="form-control datetimepicker"
                                placeholder="Please choose date & time..."
                                v-model="endDate"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <button
                        class="btn btn-raised btn-primary waves-effect"
                        type="submit"
                      >
                        <span v-if="loading">Loading...</span>
                        <span v-else>SUBMIT</span>
                      </button>
                      <button
                        class="btn btn-raised btn-warning waves-effect"
                        type="submit"
                        @click="clearFields"
                      >
                        CANCEL
                      </button>
                    </form>
                  </div>
                </div>
                <button
                  class="btn btn-danger float-right"
                  @click="$emit('close')"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- </div> -->
    </div>
  </section>
</template>

<script>
import { useToast } from "vue-toastification";
import { format } from "date-fns";
import SMSService from "../services/smsService";
import Cookies from "js-cookie";
export default {
  name: "SendSmsPage",
  data() {
    return {
      isScheduled: false,
      scheduleType: "once",
      scheduleTime: "",
      startDate: "",
      endDate: "",
      SenderID: [],
      phoneNumber: "",
      selectedSender: "",
      message: "",
      file: null,
      campaign_name: "",
      contactgroup: [],
      selectedGroupName: "",
      loading: false,
      repeat_interval_value: null,
    };
  },
  methods: {
    logSenderID() {
      if (this.selectedSender) {
        console.log("Selected Sender:", this.selectedSender);
      }
    },
    handleScheduleChange() {
      console.log("Selected Schedule Type:", this.scheduleType);
      if (this.scheduleType === "once") {
        this.endDate = "";
      }
    },
    handleFileUpload() {
      this.file = event.target.files[0];
    },

    async fetchSenders() {
      try {
        const token = Cookies.get("auth_token");
        // console.log(token)

        const response = await SMSService.post(
          `client/senderid/view/list`,
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        );

        console.log("Sender IDs:", response.data.message);
        if (response.data.status == 200) {
          this.SenderID = response.data.message;
        }
      } catch (err) {
        console.log(err);
        
      }
    },

    async sendSms() {
      const toast = useToast();

      if (!this.phoneNumber || !this.selectedSender || !this.message) {
        toast.warning("Please fill all the required fields");
        return;
      }

      try {
        const token = Cookies.get("auth_token");

        this.loading = true;

        const now = new Date();
        const formattedDate = format(now, "dd MMM yy h:mm a");

        const route = this.isScheduled
          ? "message/send/promotional"
          : "message/send/transactional";

        let payload = {
          country_code: "KE",
          message: this.message,
          msisdn: this.phoneNumber,
          schedule: this.isScheduled,
          sender_id: this.selectedSender,
        };
        if (this.isScheduled) {
          if (this.scheduleType === "NO_REPEAT") {
            payload.date = this.startDate;
          } else {
            payload.start_date = this.startDate;
          }

          payload = {
            ...payload,
            campaign_name: `SMS Created at ${formattedDate}`,
            // start_date: this.startDate,
            send_time: this.scheduleTime,
            repeat_type: this.scheduleType,
            // repeat_interval_value: this.repeat_interval_value.toString()
          };

          if (this.scheduleType === "EVERY_DAY") {
            payload.repeat_interval_value = 1;
          } else if (this.scheduleType === "EVERY_WEEK") {
            payload.repeat_interval_value = 7;
          } else if (this.scheduleType === "EVERY_MONTH") {
            payload.repeat_interval_value = 30;
          }

          if (this.scheduleType !== "NO_REPEAT") {
            payload.end_date = this.endDate;
          }
        }

        console.log("payload mate", payload);

        const res = await SMSService.post(route, payload, {
          headers: {
            Authorization: token,
          },
        });
        console.log(res);

        if (res.status == 200) {
          toast.success(res.data.message);
          return;
        }
      } catch (err) {
        console.log(err);

        if (err.status == 422) {
          toast.error("Failed, wrong inputs");
          return;
        }
        else if(err.status == 402){
          toast.error(err.response.data.message)
        }
      } finally {
        this.loading = false;
      }
    },

    async fetchContactGroups() {
      try {
        const token = Cookies.get("auth_token");

        const response = await SMSService.post(
          `contact/group`,
          {},
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("contact Groups", response.data.message);

        if (response.data.status == 200) {
          this.contactgroup = response.data.message;
          // console.log('data comming in ', this.contactgroup)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async CreateList() {
      const toast = useToast();

      if (
        !this.selectedGroupName ||
        !this.campaign_name ||
        !this.message ||
        !this.selectedSender
      ) {
        toast.warning("Please fill all the required fields");
        return;
      }

      try {
        const token = Cookies.get("auth_token");

        this.loading = true;

        // let payload = {
        //   campaign_name: this.campaign_name,
        //   message: this.message,
        //   sender_id: this.selectedSender,
        //   contact_group_id: this.selectedGroupName,
        //   schedule: this.isScheduled,
        // };

        // if (this.isScheduled) {
        //   payload = {
        //     ...payload,
        //     // campaign_name: `SMS Created at ${formattedDate}`,
        //     date: this.startDate,
        //     send_time: this.scheduleTime,
        //     repeat_type: this.scheduleType,
        //   };
        //   if (this.scheduleType !== "NO_REPEAT") {
        //     payload.end_date = this.endDate;
        //   }
        // }

        let payload = {
          // country_code: "KE",
          campaign_name: this.campaign_name,
          message: this.message,
          // msisdn: this.phoneNumber,
          contact_group_id: this.selectedGroupName,
          schedule: this.isScheduled,
          sender_id: this.selectedSender,
        };
        if (this.isScheduled) {
          if (this.scheduleType === "NO_REPEAT") {
            payload.date = this.startDate;
          } else {
            payload.start_date = this.startDate;
          }

          payload = {
            ...payload,
            // campaign_name: `SMS Created at ${formattedDate}`,
            // start_date: this.startDate,
            send_time: this.scheduleTime,
            repeat_type: this.scheduleType,
            // repeat_interval_value: this.repeat_interval_value.toString()
          };

          if (this.scheduleType === "EVERY_DAY") {
            payload.repeat_interval_value = 1;
          } else if (this.scheduleType === "EVERY_WEEK") {
            payload.repeat_interval_value = 7;
          } else if (this.scheduleType === "EVERY_MONTH") {
            payload.repeat_interval_value = 30;
          }

          if (this.scheduleType !== "NO_REPEAT") {
            payload.end_date = this.endDate;
          }
        }

        console.log("our data", payload);

        const response = await SMSService.post(
          "message/send/contact-group",
          payload,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        console.log(response);

        if (response.status == 201) {
          toast.success(response.data.message);
          this.clearFields();
          return;
        }
      } catch (err) {
        console.log(err);
        if (err.status == 422) {
          toast.error("Failed, wrong inputs");
          return;
        }
      } finally {
        this.loading = false;
      }
    },

    logGroupName() {
      if (this.selectedGroupName) {
        console.log("Selected Group Name:", this.selectedGroupName);
      }
    },

    async uploadContact() {
      const toast = useToast();
      if (!this.campaign_name || !this.selectedSender || !this.message) {
        toast.warning("Please fill all the required fields");
        return;
      }

      try {
        const token = Cookies.get("auth_token");

        this.loading = true;
        // const formData = new FormData();
        // formData.append("file", this.file);
        // formData.append("campaign_name", this.campaign_name);
        // formData.append("sender_id", this.selectedSender);
        // formData.append("message", this.message);
        // formData.append("schedule", this.isScheduled ? "true" : "false");

        // if (this.isScheduled) {
        //   formData.append("repeat_type", this.scheduleType);
        //   formData.append("date", this.startDate);
        //   formData.append("send_time", this.scheduleTime);

        //   if (this.scheduleType !== "NO_REPEAT") {
        //     formData.append("end_date", this.endDate);
        //   }
        // }

        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("campaign_name", this.campaign_name);
        formData.append("sender_id", this.selectedSender);
        formData.append("message", this.message);
        formData.append("schedule", this.isScheduled ? "true" : "false");

        if (this.isScheduled) {
          formData.append("repeat_type", this.scheduleType);
          formData.append("send_time", this.scheduleTime);

          // If the schedule type is "SEND ONLY ONCE"
          if (this.scheduleType === "NO_REPEAT") {
            formData.append("date", this.startDate); // Use "date" for SEND ONLY ONCE
          } else {
            // For repeat options, use "start_date" and add "repeat_interval_value"
            formData.append("start_date", this.startDate);

            // Dynamically set the repeat_interval_value
            let repeatInterval;
            switch (this.scheduleType) {
              case "EVERY_DAY":
                repeatInterval = 1;
                break;
              case "EVERY_WEEK":
                repeatInterval = 7;
                break;
              case "EVERY_MONTH":
                repeatInterval = 30;
                break;
              default:
                repeatInterval = null;
            }

            // Append repeat_interval_value if it's a repeating schedule
            if (repeatInterval !== null) {
              formData.append(
                "repeat_interval_value",
                repeatInterval.toString()
              );
            }

            // Append end_date if it's not a "SEND ONLY ONCE" schedule
            formData.append("end_date", this.endDate);
          }
        }

        console.log("my form data", formData);

        const response = await SMSService.post(
          "message/send/upload-file",
          formData,
          {
            headers: {
              Authorization: token,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log(response);
        if (response.status == 201) {
          toast.success(response.data.message);
          this.clearFields();
          return;
        }
      } catch (err) {
        console.log(err);
        if (err.status == 422) {
          toast.error("Failed, wrong inputs");
          return;
        }
      } finally {
        this.loading = false;
      }
    },

    clearFields() {
      this.phoneNumber = "";
      this.selectedSender = "";
      this.message = "";
      this.isScheduled = false;
      this.scheduleTime = null;
      this.scheduleDate = null;
      this.campaign_name = "";
      this.file = null;
    },
  },
  mounted() {
    this.fetchSenders();
    this.fetchContactGroups();
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: #fff;
  z-index: 1000;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.1);
}

.modal-content {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  max-height: 100%;
}

.modal-body-scroll {
  flex: 1;
  overflow-y: auto;
}

.modal-body-scroll::-webkit-scrollbar {
  width: 8px;
}

.modal-body-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

@media screen and (max-width: 568px) {
  .modal-overlay {
    width: 90%;
  }
  /* .zmdi-arrow-right{
    display: none;
  }

  .zmdi zmdi-close{
    display: block;
  } */
}
</style>
