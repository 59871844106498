import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import IndexStore from "./store/indexStore";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import axios from "axios";
import { useToast } from "vue-toastification";

const app = createApp(App);

const options = {
  position: POSITION.TOP_CENTER,
  timeout: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

app.use(router);
app.use(IndexStore);
app.use(Toast, options);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403 || error.response.status === 428)
    ) {
      const toast = useToast();
      toast.info("Token expired. You have been logged out.");

      IndexStore.dispatch("login/LogoutUser");

      router.push("/");
    }

    return Promise.reject(error);
  }
);

app.mount("#app");
