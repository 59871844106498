<template>
  <div>
    <section class="content">
      <div class="body_scroll">
        <div class="block-header">
          <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
              <h2>Network SMS Report</h2>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href=" /"><i class="zmdi zmdi-home"></i> Admin</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="javascript:void(0);">Network SMS</a>
                </li>
                <li class="breadcrumb-item active">Report</li>
              </ul>
              <button
                class="btn btn-primary btn-icon mobile_menu-new"
                type="button"
                @click="$root.toggleSidebar"
              >
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
              <button
                class="btn btn-primary btn-icon float-right right_icon_toggle_btn"
                type="button"
              >
                <i class="zmdi zmdi-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>

        <section class="d-flex flex-wrap justify-content-between mb-4">
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="dateRangeSelect">Select Date Range</label>
              <select
                class="form-control"
                id="dateRangeSelect"
                v-model="selectedOption"
                @change="handleDateRangeChange"
              >
                <option value="choose">Choose Custom</option>
                <option value="all">All SMS</option>
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="last7">Last 7 Days</option>
                <option value="lastMonth">Last Month</option>
                <option value="thisMonth">This Month</option>
                <option value="custom">Custom Range</option>
              </select>
            </div>

            <div v-if="showCustomRange">
              <div class="form-group">
                <label for="startDate">Start Date</label>
                <input
                  type="date"
                  class="form-control"
                  id="startDate"
                  v-model="startDate"
                  @change="handleCustomDateChange"
                />
              </div>
              <div class="form-group">
                <label for="endDate">End Date</label>
                <input
                  type="date"
                  class="form-control"
                  id="endDate"
                  v-model="endDate"
                  @change="handleCustomDateChange"
                />
              </div>
            </div>
          </div>
        </section>

        <div class="container-fluid">
          <div class="row clearfix">
            <div class="col-lg-12">
              <div class="card">
                <div class="body">
                  <div class="table-responsive">
                    <table
                      class="table table-bordered table-striped table-hover"
                    >
                      <thead>
                        <tr>
                          <th>#</th>

                          <th>Connector name</th>
                          <th>Sent</th>
                          <th>Delivered</th>
                          <th>Pending</th>
                          <th>Failed</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="loading">
                          <td colspan="4">Loading...</td>
                        </tr>
                        <tr v-else-if="paginatedSms.length === 0">
                          <td colspan="4">No Network SMS History Found</td>
                        </tr>
                        <tr
                          v-else
                          v-for="(sms, index) in paginatedSms"
                          :key="sms.connector_id"
                        >
                          <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>

                          <td>{{ sms.connector_name || "N/A" }}</td>
                          <td>{{ sms.sent || "N/A" }}</td>
                          <td>{{ sms.delivered || "N/A" }}</td>
                          <td>{{ sms.pending || "N/A" }}</td>
                          <td>{{ sms.failed || "N/A" }}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>#</th>

                          <th>Connector name</th>
                          <th>Sent</th>
                          <th>Delivered</th>
                          <th>Pending</th>
                          <th>Failed</th>
                        </tr>
                      </tfoot>
                    </table>

                    <!-- Pagination -->
                    <nav aria-label="Page navigation">
                      <ul class="pagination">
                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(1)"
                            >First</a
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(currentPage - 1)"
                            >Previous</a
                          >
                        </li>
                        <li
                          v-for="page in limitedPageRange"
                          :key="page"
                          class="page-item"
                          :class="{ active: currentPage === page }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(page)"
                            >{{ page }}</a
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === totalPages }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(currentPage + 1)"
                            >Next</a
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === totalPages }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(totalPages)"
                            >Last</a
                          >
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SMSService from "../services/smsService";
import Cookies from "js-cookie";
import { useToast } from "vue-toastification";
import { format, startOfMonth, endOfMonth, subDays, subMonths } from "date-fns";

export default {
  name: "GlobalSMSPage",
  data() {
    return {
      transactionalSms: [],
      filteredSms: [],
      loading: false,
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      type: "connector",
      showCustomRange: false,
      selectedOption: "all",
      startDate: "2018-02-07",
      endDate: format(new Date(), "yyyy-MM-dd"),

      // sender
    };
  },
  computed: {
    paginatedSms() {
      return this.transactionalSms;
      // return this.filteredSms;
    },
    limitedPageRange() {
      const start = Math.max(1, this.currentPage - 5);
      const end = Math.min(this.totalPages, start + 9);
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
  },
  methods: {
    handleDateRangeChange() {
      const today = new Date();

      switch (this.selectedOption) {
        case "all": {
          this.startDate = "2018-02-07";
          this.endDate = format(today, "yyyy-MM-dd");
          this.showCustomRange = false;
          break;
        }

        case "today": {
          const formattedToday = format(today, "yyyy-MM-dd");
          this.startDate = formattedToday;
          this.endDate = formattedToday;
          this.showCustomRange = false;
          break;
        }

        case "yesterday": {
          const yesterday = subDays(today, 1);
          const formattedYesterday = format(yesterday, "yyyy-MM-dd");
          this.startDate = formattedYesterday;
          this.endDate = formattedYesterday;
          this.showCustomRange = false;
          break;
        }

        case "last7": {
          const last7Days = subDays(today, 7);
          this.startDate = format(last7Days, "yyyy-MM-dd");
          this.endDate = format(today, "yyyy-MM-dd");
          this.showCustomRange = false;
          break;
        }

        case "lastMonth": {
          const lastMonth = subMonths(today, 1);
          this.startDate = format(startOfMonth(lastMonth), "yyyy-MM-dd");
          this.endDate = format(endOfMonth(lastMonth), "yyyy-MM-dd");
          this.showCustomRange = false;
          break;
        }

        case "thisMonth": {
          this.startDate = format(startOfMonth(today), "yyyy-MM-dd");
          this.endDate = format(today, "yyyy-MM-dd");
          this.showCustomRange = false;
          break;
        }

        case "custom": {
          this.showCustomRange = true;
          break;
        }

        default: {
          this.startDate = "";
          this.endDate = "";
          this.showCustomRange = false;
        }
      }
      console.log("Selected Option:", this.selectedOption);
      console.log("Start Date:", this.startDate);
      console.log("End Date:", this.endDate);
      this.fetchTransactional();
    },

    handleCustomDateChange() {
      console.log("Custom Start Date:", this.startDate);
      console.log("Custom End Date:", this.endDate);

      this.fetchTransactional();
    },

    async fetchTransactional() {
      try {
        const toast = useToast();
        const token = Cookies.get("auth_token");
        let userId = localStorage.getItem("user_id");
        if (!userId) {
          toast.info("User ID is missing. Cannot fetch Topup data.");
          this.$router.push("/");
          return;
        }
        userId = parseInt(userId, 10);
        this.loading = true;

        const data = {
          id: userId,
          start: this.startDate,
          end: this.endDate,
          // page: this.currentPage,
          // per_page: this.perPage,
        };
        console.log("my data", data);

        const response = await SMSService.post(
          `client/reports/sms/${this.type}`, //network report
          // "client/reports/consumption",
          data,

          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log("history is netowrks", response);

        if (response.status === 200) {
          this.transactionalSms = response.data.message || [];
          // console.log("length", this.transactionalSms.length);
          this.totalPages = response.data.last_page || 1;
        }
      } catch (err) {
        console.error("Error fetching transactional data:", err);
        const toast = useToast();

        if (err.status == 422) {
          toast.info(err.response.data.message);
          // this.$router.push("/");
          // return;
        }
        this.transactionalSms = [];
      } finally {
        this.loading = false;
      }
    },

    async changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        await this.fetchTransactional();
      }
    },

    formatDate(dateStr) {
      const date = new Date(dateStr);
      return format(date, "do MMM yyyy h:mm a");
    },
  },

  mounted() {
    this.fetchTransactional();
  },
};
</script>

<style scoped>
.mobile_menu-new {
  display: none;
}
@media screen and (max-width: 568px) {
  .mobile_menu-new {
    display: block;
    float: right;
  }
}
.text-success {
  color: green;
}
.text-danger {
  color: red;
}
</style>
