<template>
  <section class="content modal-overlay">
    <div class="body_scroll modal-content">
      <div class="block-header">
        <div class="row">
          <div class="col-lg-7 col-md-6 col-sm-12"></div>
          <div class="col-lg-5 col-md-6 col-sm-12">
            <button
              class="btn btn-primary btn-icon float-right right_icon_toggle_btn"
              type="button"
              @click="$emit('close')"
            >
              <i class="zmdi zmdi-close"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- <div class="modal-body-scroll"> -->

      <div class="container-fluid">
        <div class="row clearfix">
          <div class="col-sm-12">
            <div class="card">
              <div class="body">
                <!-- Tab panes -->
                <div class="tab-content">
                  <div
                    role="tabpanel"
                    class="tab-pane in active"
                    id="profile_only_icon_title"
                  >
                    <div class="card">
                      <div class="col-12">
                        <div class="alert alert-warning" role="alert">
                          <strong
                            >Send SMS to
                            {{
                              campaign_name && campaign_name
                                ? campaign_name
                                : "Camapign name"
                            }}</strong
                          >
                        </div>
                      </div>
                      <form class="body" @submit.prevent="sendSms">
                        <h2 class="card-inside-title">Select Contact Group</h2>
                        <div class="row clearfix">
                          <div class="col-sm-12">
                            <select
                              class="form-control"
                              required
                              v-model="selectedGroupId"
                              v-if="contactgroup && contactgroup.length > 0"
                              @change="logGroupName"
                            >
                              <option disabled value="">
                                Select Contact Group
                              </option>
                              <option
                                v-for="group in contactgroup"
                                :key="group.id"
                                :value="group.id"
                              >
                                {{ group.name }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="row clearfix mt-3">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label>Campaign Name</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="SMS TO ....."
                                v-model="campaign_name"
                              />
                            </div>
                          </div>
                        </div>
                        <h2 class="card-inside-title">Sender Id</h2>
                        <div class="row clearfix">
                          <div class="col-sm-12">
                            <select
                              class="form-control show-tick"
                              v-model="selectedSender"
                              v-if="SenderID.length > 0"
                              @change="logSenderID"
                            >
                              <option disabled value="">
                                Select Sender ID
                              </option>

                              <option
                                v-for="sender in SenderID"
                                :key="sender.id"
                                :value="sender.sender_id"
                              >
                                {{ sender.sender_id }}
                                {{ sender.carrier_name }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <h2 class="card-inside-title">Message</h2>
                        <div class="row clearfix">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <div class="form-line">
                                <textarea
                                  rows="4"
                                  class="form-control no-resize"
                                  placeholder="Please type what you want..."
                                  v-model="message"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="checkbox">
                          <input
                            id="remember_me"
                            type="checkbox"
                            v-model="isScheduled"
                          />
                          <label for="remember_me">
                            Schedule or Create a reminder?
                          </label>
                        </div>

                        <div v-if="isScheduled" class="modal-body-scroll">
                          <div class="row clearfix">
                            <div class="col-sm-12">
                              <h6>Choose when and how to Send this message</h6>
                              <select
                                class="form-control show-tick"
                                v-model="scheduleType"
                                @change="handleScheduleChange"
                              >
                                <option disabled value="">
                                  Choose Frequency
                                </option>
                                <option value="NO_REPEAT">
                                  SEND ONLY ONCE
                                </option>
                                <option value="EVERY_DAY">
                                  REPEAT EVERYDAY
                                </option>
                                <option value="EVERY_WEEK">
                                  REPEAT EVERY WEEK
                                </option>
                                <option value="EVERY_MONTH">
                                  REPEAT EVERY MONTH
                                </option>
                              </select>
                            </div>
                          </div>
                          <br />
                          <br />

                          <div class="row clearfix">
                            <div class="col-sm-6 mt-lg-0">
                              <h6>Time</h6>
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"
                                    ><i class="zmdi zmdi-time"></i
                                  ></span>
                                </div>
                                <input
                                  type="time"
                                  class="form-control timepicker"
                                  placeholder="Please choose a time..."
                                  v-model="scheduleTime"
                                />
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <h6>Start Date</h6>
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"
                                    ><i class="zmdi zmdi-calendar"></i
                                  ></span>
                                </div>
                                <input
                                  type="date"
                                  class="form-control datetimepicker"
                                  placeholder="Please choose date & time..."
                                  v-model="startDate"
                                />
                              </div>
                            </div>

                            <div
                              class="col-sm-6 mt-3"
                              v-if="scheduleType !== 'NO_REPEAT'"
                            >
                              <h6>End Date</h6>
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"
                                    ><i class="zmdi zmdi-calendar"></i
                                  ></span>
                                </div>
                                <input
                                  type="date"
                                  class="form-control datetimepicker"
                                  placeholder="Please choose date & time..."
                                  v-model="endDate"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <button
                          class="btn btn-raised btn-primary waves-effect"
                          type="submit"
                        >
                          <span v-if="loading">Loading...</span>
                          <span v-else>SUBMIT</span>
                        </button>
                        <button
                          class="btn btn-raised btn-warning waves-effect"
                          type="button"
                          @click="clearFields"
                        >
                          CANCEL
                        </button>
                      </form>
                    </div>
                  </div>
                </div>

                <button
                  class="btn btn-danger float-right"
                  @click="$emit('close')"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- </div> -->
    </div>
  </section>
</template>

<script>
import { useToast } from "vue-toastification";
// import { format } from "date-fns";
import SMSService from "../../services/smsService";
import Cookies from "js-cookie";
export default {
  name: "SendSmsPage",
  data() {
    return {
      isScheduled: false,
      scheduleType: "once",
      scheduleTime: "",
      startDate: "",
      endDate: "",
      SenderID: [],
      selectedSender: "",
      message: "",

      campaign_name: "",
      contactgroup: [],
      selectedGroupId: "",
      loading: false,
    };
  },
  methods: {
    logSenderID() {
      if (this.selectedSender) {
        console.log("Selected Sender:", this.selectedSender);
      }
    },

    handleScheduleChange() {
      console.log("Selected Schedule Type:", this.scheduleType);
      if (this.scheduleType === "once") {
        this.endDate = "";
      }
    },

    async fetchSenders() {
      try {
        const token = Cookies.get("auth_token");
        // console.log(token)

        const response = await SMSService.post(
          `client/senderid/view/list`,
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        );

        console.log("Sender IDs:", response.data.message);
        if (response.data.status == 200) {
          this.SenderID = response.data.message;
        }
      } catch (err) {
        console.log(err);
      }
    },

    async sendSms() {
      const toast = useToast();

      if (
        !this.campaign_name ||
        !this.selectedSender ||
        !this.message ||
        !this.selectedGroupId
      ) {
        toast.warning("Please fill all the required fields");
        return;
      }

      try {
        const token = Cookies.get("auth_token");

        this.loading = true;

        // const now = new Date();
        // const formattedDate = format(now, "dd MMM yy h:mm a");

        const route = this.isScheduled
          ? "message/send/contact-group"
          : "message/send/contact-group";

        let payload = {
          campaign_name: this.campaign_name,
          contact_group_id: this.selectedGroupId,
          message: this.message,

          schedule: this.isScheduled,
          sender_id: this.selectedSender,
        };
        if (this.isScheduled) {
          if (this.scheduleType === "NO_REPEAT") {
            payload.date = this.startDate;
          } else {
            payload.start_date = this.startDate;
          }

          payload = {
            ...payload,

            // date: this.startDate,
            send_time: this.scheduleTime,
            repeat_type: this.scheduleType,
          };

          if (this.scheduleType === "EVERY_DAY") {
            payload.repeat_interval_value = 1;
          } else if (this.scheduleType === "EVERY_WEEK") {
            payload.repeat_interval_value = 7;
          } else if (this.scheduleType === "EVERY_MONTH") {
            payload.repeat_interval_value = 30;
          }

          if (this.scheduleType !== "NO_REPEAT") {
            payload.end_date = this.endDate;
          }
        }

        console.log("payload mate", payload);

        const res = await SMSService.post(route, payload, {
          headers: {
            Authorization: token,
          },
        });
        console.log(res);

        if (res.status == 201) {
          toast.success(res.data.message);
          return;
        }
      } catch (err) {
        console.log(err);

        if (err.status == 422) {
          toast.error(err.response.data.message || "Failed, wrong inputs");
          return;
        }
      } finally {
        this.loading = false;
      }
    },

    async fetchContactGroups() {
      try {
        const token = Cookies.get("auth_token");

        const response = await SMSService.post(
          `contact/group`,
          {},
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("contact Groups", response.data.message);

        if (response.data.status == 200) {
          this.contactgroup = response.data.message;
          // console.log('data comming in ', this.contactgroup)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    logGroupName() {
      if (this.selectedGroupId) {
        console.log("Selected Group ID:", this.selectedGroupId);
      }
    },

    clearFields() {
      this.phoneNumber = "";
      this.selectedSender = "";
      this.message = "";
      this.isScheduled = false;
      this.scheduleTime = null;
      this.scheduleDate = null;
      this.campaign_name = "";
      this.file = null;
    },
  },
  mounted() {
    this.fetchSenders();
    this.fetchContactGroups();
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: #fff;
  z-index: 1000;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.1);
}

.modal-content {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  max-height: 100%;
}

.modal-body-scroll {
  flex: 1;
  overflow-y: auto;
}

.modal-body-scroll::-webkit-scrollbar {
  width: 8px;
}

.modal-body-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

@media screen and (max-width: 568px) {
  .modal-overlay {
    width: 90%;
  }
  /* .zmdi-arrow-right{
    display: none;
  }

  .zmdi zmdi-close{
    display: block;
  } */
}
</style>
