<template>
  <div>
    <section class="content">
      <div class="body_scroll">
        <div class="block-header">
          <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
              <h2>User Roles</h2>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href=" /"><i class="zmdi zmdi-home"></i> Admin</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="javascript:void(0);">User</a>
                </li>
                <li class="breadcrumb-item active">Roles</li>
              </ul>
              <!-- <button
                class="btn btn-primary btn-icon mobile_menu"
                type="button"
              >
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button> -->
              <button
                class="btn btn-primary btn-icon mobile_menu-new"
                type="button"
                @click="$root.toggleSidebar"
              >
                <!-- @click="$emit('toggle-sidebar')" -->
                <!-- @click ="$root.toggleSidebar" -->
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
              <button
                class="btn btn-primary btn-icon float-right right_icon_toggle_btn"
                type="button"
              >
                <i class="zmdi zmdi-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <!--   -->

          <div class="main-contact-group-container">
            <div class="main-contact-group-container-upper">
              <p style="font-weight: bold">Active Roles</p>

              <div class="d-flex flex-column">
                <button
                  class="btn btn-sm"
                  type="button"
                  data-toggle="modal"
                  data-target="#groupModal"
                >
                  Create new Role
                </button>
              </div>
            </div>

            <!-- <div
              v-if="activeRoles && activeRoles.length"
              class="contact-group-inner"
            >
              <div
                class="contact-groups-div"
                v-for="role in activeRoles"
                :key="role.id"
                @click="handleRoleClick(role)"
              >
                <p v-if="role && role.name" style="font-weight: 600">
                  {{ role.name }}
                </p>
              </div>
            </div> -->

            <ul
              class="nav nav-pills nav-fill contact-group-list"
              v-if="activeRoles && activeRoles.length"
            >
              <li
                class="nav-item"
                v-for="role in activeRoles"
                :key="role.id"
                @click="handleRoleClick(role)"
              >
                <a class="nav-link contact-group-link">
                  <span class="group-name">{{ role.name }}</span>
                </a>
              </li>
            </ul>

            <div v-else class="text-center" style="margin-top: 20px">
              <p>No active roles available.</p>
            </div>
          </div>

          <div class="main-contact-group-container" style="margin-top: 30px">
            <div class="main-contact-group-container-upper">
              <p style="font-weight: bold">Suspended Roles</p>
            </div>

            <div
              v-if="suspendedRoles && suspendedRoles.length"
              class="contact-group-inner"
            >
              <div
                class="contact-groups-div"
                v-for="role in suspendedRoles"
                :key="role.id"
                @click="handleRoleClick(role)"
              >
                <p v-if="role && role.name" style="font-weight: 600">
                  {{ role.name }}
                </p>
              </div>
            </div>
            <div v-else class="text-center" style="margin-top: 20px">
              <p>No suspended roles available.</p>
            </div>
          </div>

          <!-- MODAL FOR CREATING A NEW CONTACT GROUP -->

          <div class="modal fade" id="groupModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="title" id="largeModalLabel">Add New Role</h4>
                </div>
                <div class="modal-body">
                  <div class="body">
                    <form id="form_validation" @submit.prevent="CreateRole">
                      <div class="form-group form-float">
                        <label class="fw-bold">Role Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Role name"
                          name="group"
                          required
                          v-model="role_name"
                        />
                      </div>

                      <button
                        class="btn btn-raised btn-primary waves-effect"
                        type="submit"
                      >
                        <span v-if="loading">Loading...</span>
                        <span v-else>Create Role</span>
                      </button>
                    </form>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger waves-effect"
                    data-dismiss="modal"
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- MODAL FOR CREATING A NEW CONTACT GROUP -->

          <!-- MODAL FOR UPDATING A CONTACT GROUP -->

          <div class="modal fade" id="updateModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="title" id="largeModalLabel">
                    Add Permission for {{ modalRole ? modalRole.name : "" }}
                  </h4>
                </div>
                <div class="modal-body">
                  <div class="body">
                    <form
                      id="form_validation"
                      @submit.prevent="permissionUpdate(modalRole)"
                    >
                      <div class="form-group form-float">
                        <label class="fw-bold">Select Permission</label>

                        <select
                          class="form-control"
                          v-model="selectedPermission"
                          required
                        >
                          <option disabled value="">Select Permission</option>
                          <option value="read">Read</option>
                          <option value="create">Create</option>
                          <option value="delete">Delete</option>
                          <option value="update">Update</option>
                        </select>
                      </div>

                      <button
                        class="btn btn-raised btn-primary waves-effect"
                        type="submit"
                      >
                        <span v-if="loading">Loading...</span>
                        <span v-else>Assign Permission</span>
                      </button>
                    </form>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger waves-effect"
                    data-dismiss="modal"
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- MODAL FOR UPDATING A CONTACT GROUP -->

          <!-- MODAL FOR DELETING A CONTACT GROUP -->

          <div class="modal fade" id="actionModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="title" id="actionModal">
                    Are you sure you want to revoke Permission
                    {{ modalActionName ? modalActionName : "" }} from
                    {{ modalRole ? modalRole.name : "" }}
                  </h4>
                </div>
                <div class="modal-body">
                  <div class="body">
                    <button
                      class="btn btn-raised btn-primary waves-effect"
                      type="submit"
                      @click="handleAction"
                    >
                      <span v-if="loading">Loading...</span>
                      <span v-else>OK</span>
                    </button>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger waves-effect"
                    data-dismiss="modal"
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- MODAL FOR DELETING A CONTACT GROUP -->

          <!-- MODAL FOR UPDATING A SINGLE CONTACT -->

          <div
            class="modal fade"
            id="editRoleModal"
            tabindex="-1"
            role="dialog"
          >
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="title" id="largeModalLabel">
                    Update Role
                    {{ modalRole ? modalRole.name : "" }}
                  </h4>
                </div>
                <div class="modal-body">
                  <div class="body">
                    <form
                      id="form_validation"
                      v-if="modalRole"
                      @submit.prevent="editRole(modalRole)"
                    >
                      <div class="form-group form-float">
                        <label class="fw-bold">New Role Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Role Name"
                          required
                          v-model="new_role_name"
                        />
                      </div>

                      <button
                        class="btn btn-raised btn-primary waves-effect"
                        type="submit"
                      >
                        <span v-if="loading">Loading...</span>
                        <span v-else>Update Role Name</span>
                      </button>
                    </form>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger waves-effect"
                    data-dismiss="modal"
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- MODAL FOR UPDATING A SINGLE CONTACT -->

          <!-- MODAL FOR MUTING A SINGLE CONTACT -->

          <div
            class="modal fade"
            id="deactivateModal"
            tabindex="-1"
            role="dialog"
          >
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="title" id="deactivateModal">
                    <div v-if="modalRole && modalRole.status == 1">
                      Deactivate
                      {{ modalRole ? modalRole.name : "" }}
                    </div>

                    <div v-else>
                      Activate
                      {{ modalRole ? modalRole.name : "" }}
                    </div>
                  </h4>
                </div>
                <div class="modal-body">
                  <div class="body">
                    <button
                      class="btn btn-raised btn-primary waves-effect"
                      type="submit"
                      @click="activateRole(modalRole)"
                    >
                      <span v-if="loading">Loading...</span>
                      <span v-else>{{
                        modalRole && modalRole.status == 1
                          ? "Deactivate"
                          : "Activate"
                      }}</span>
                    </button>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger waves-effect"
                    data-dismiss="modal"
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- MODAL FOR MUTING A SINGLE CONTACT -->

          <!-- MODAL FOR DELETING A SINGLE CONTACT -->

          <div
            class="modal fade"
            id="deleteRoleModal"
            tabindex="-1"
            role="dialog"
          >
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="title" id="deleteRoleModal">
                    Are you sure you want to Delete Role
                    {{ modalRole ? modalRole.name : "" }}
                  </h4>
                </div>
                <div class="modal-body">
                  <div class="body">
                    <button
                      class="btn btn-raised btn-primary waves-effect"
                      type="submit"
                      @click="deleteRole(modalRole)"
                    >
                      <span v-if="loading">Loading...</span>
                      <span v-else> Delete Role</span>
                    </button>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger waves-effect"
                    data-dismiss="modal"
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- MODAL FOR DELETING A SINGLE CONTACT -->

          <div class="row clearfix">
            <div class="col-lg-12">
              <div class="card">
                <div class="header">
                  <button
                    type="button"
                    class="btn btn-warning waves-effect m-r-20"
                    data-toggle="modal"
                    data-target="#deactivateModal"
                    @click="setModalRole(selectedRole)"
                    v-if="selectedRole && selectedRole.status == 1"
                  >
                    Deactivate Role
                  </button>
                  <button
                    type="button"
                    class="btn btn-warning waves-effect m-r-20"
                    data-toggle="modal"
                    data-target="#deactivateModal"
                    @click="setModalRole(selectedRole)"
                    v-else
                  >
                    Activate Role
                  </button>

                  <button
                    type="button  "
                    class="btn btn-primary waves-effect m-r-20"
                    data-toggle="modal"
                    data-target="#editRoleModal"
                    @click="setModalRole(selectedRole)"
                    v-if="selectedRole && selectedRole.status == 1"
                  >
                    Edit Role
                  </button>

                  <button
                    type="button  "
                    class="btn btn-danger waves-effect m-r-20"
                    data-toggle="modal"
                    data-target="#deleteRoleModal"
                    @click="setModalRole(selectedRole)"
                  >
                    Delete Role
                  </button>

                  <div
                    class="modal fade"
                    id="largeModal"
                    tabindex="-1"
                    role="dialog"
                  >
                    <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="title" id="largeModalLabel">
                            Add Contacts
                          </h4>
                        </div>
                        <div class="modal-body">
                          <div class="body">
                            <form
                              id="form_validation"
                              @submit.prevent="CreateContact"
                            >
                              <div class="form-group form-float">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Country"
                                  name="country"
                                  required
                                  v-model="country"
                                  style="cursor: not-allowed"
                                />
                              </div>
                              <div class="form-group form-float">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Mobile Number eg. 0784******"
                                  name="mobile-number"
                                  required
                                  v-model="mobileNumber"
                                />
                              </div>
                              <div class="form-group form-float">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="First Name"
                                  name="first_name"
                                  required
                                  v-model="firstName"
                                />
                              </div>

                              <div class="form-group form-float">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Last Name"
                                  name="last_name"
                                  required
                                  v-model="lastName"
                                />
                              </div>

                              <div class="form-group form-float">
                                <select
                                  class="form-control"
                                  required
                                  v-model="selectedGroupName"
                                  v-if="contactgroup && contactgroup.length > 0"
                                  @change="logGroupName"
                                >
                                  <option disabled value="">
                                    Select Contact Group
                                  </option>
                                  <option
                                    v-for="group in contactgroup"
                                    :key="group.id"
                                    :value="group.name"
                                  >
                                    {{ group.name }}
                                  </option>
                                </select>
                              </div>

                              <button
                                class="btn btn-raised btn-primary waves-effect"
                                type="submit"
                              >
                                <span v-if="loading">Loading...</span>
                                <span v-else>SUBMIT</span>
                              </button>
                            </form>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-danger waves-effect"
                            data-dismiss="modal"
                          >
                            CLOSE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="body">
                  <div class="table-responsive">
                    <h2 class="text-center">
                      Displaying Data for {{ selectedRoleName }}
                    </h2>
                    <table
                      class="table table-bordered table-striped table-hover js-basic-example dataTable"
                    >
                      <thead>
                        <tr>
                          <th>Module</th>
                          <th>Permission</th>
                          <th>Update</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-if="loading">
                          <td colspan="3">Loading...</td>
                        </tr>

                        <tr v-else-if="!selectedRolePermissions.length">
                          <td colspan="7">
                            No Data for {{ selectedRoleName }}
                          </td>
                        </tr>

                        <tr
                          v-else
                          v-for="permission in selectedRolePermissions"
                          :key="permission.id"
                        >
                          <td>{{ permission.module || "N/A" }}</td>

                          <!-- <td>
                            {{
                              permission.actions
                                .map((action) => action.name)
                                .join(", ") || "N/A"
                            }}
                          </td> -->
                          <td>
                            <span
                              v-for="action in permission.actions"
                              :key="action.name"
                              class="clickable-action"
                              @click="
                                openActionModal(
                                  action,
                                  permission,
                                  selectedRole
                                )
                              "
                              data-toggle="modal"
                              data-target="#actionModal"
                              style="padding: 5px"
                            >
                              {{ action.name }}
                            </span>
                          </td>

                          <td>
                            <button
                              class="btn btn-primary btn-sm"
                              data-toggle="modal"
                              data-target="#updateModal"
                              @click="
                                setModalData(permission);
                                setModalRole(selectedRole);
                              "
                            >
                              Update
                            </button>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Module</th>
                          <th>Permission</th>
                          <th>Update</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import Cookies from "js-cookie";
// import SmsService from "../services/smsService";
import IdentitySmsService from "../services/IdentitySms";
// import Identity from '../services/Auth'

export default {
  name: "UserRolePage",
  data() {
    return {
      roles: [],
      selectedRole: null,
      loading: false,
      selectedRolePermissions: [],
      selectedRoleName: "",
      role_name: "",
      modalRole: {},
      new_role_name: "",
      modalPermission: {},
      selectedPermission: "",
      modalActionName: "",
    };
  },
  computed: {
    activeRoles() {
      return this.roles.filter((role) => role.status == 1);
    },
    suspendedRoles() {
      return this.roles.filter((role) => role.status == 0);
    },
  },
  methods: {
    setModalRole(role) {
      this.modalRole = { ...role };
    },
    setModalData(permission) {
      this.modalPermission = { ...permission };
      console.log("Modal Data:", this.modalPermission);
    },
    openActionModal(action, permission, role) {
      this.modalActionName = action.name;
      this.modalPermission = { ...permission };
      this.modalRole = { ...role };

      console.log("Action Name:", this.modalActionName);
      console.log("Role ID:", this.modalRole.id);
      console.log("Permission Data:", this.modalPermission);
    },

    async fetchRoles() {
      const toast = useToast();

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");

        const res = await IdentitySmsService.post(
          "/admin/role/all",
          {},
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("roles", res);

        if (res.status == 200) {
          this.roles = res.data.message;
        }
      } catch (err) {
        console.log(err);
        if (err.status == 401) {
          toast.info(err.response.data.message);

          // this.$router.push('/')
        }
      } finally {
        this.loading = false;
      }
    },
    handleRoleClick(role) {
      this.selectedRole = role;
      this.selectedRoleName = role.name;
      this.selectedRolePermissions = role.permissions.map((permission) => ({
        module: permission.module,
        actions: permission.actions,
      }));
    },

    async CreateRole() {
      const toast = useToast();

      if (!this.role_name) {
        toast.warning("Please fill in all fields");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          name: this.role_name,
          role_type: "CLIENT",
        };

        const res = await IdentitySmsService.post("/admin/role/create", data, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });

        console.log("roles", res);

        if (res.status == 201) {
          toast.success(res.data.message);
          this.fetchRoles();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async activateRole(role) {
      const toast = useToast();
      if (!role || !role.id) {
        toast.warning("Please Select a role to proceed");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          role_id: role.id,
          status: role.status == 1 ? 0 : 1,
        };
        console.log("update data", data);

        const res = await IdentitySmsService.post("admin/role/status", data, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });

        console.log("roles", res);
        if (res.status == 201) {
          toast.success(res.data.message);
          this.fetchRoles();
        }
      } catch (err) {
        console.log(err);
        if (err.status == 422) {
          toast.error(err.response.data.message);
        } else if (err.status == 500) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },

    async editRole(role) {
      const toast = useToast();
      if (!role || !role.id) {
        toast.warning("Please Select a role to proceed");
        return;
      } else if (!this.new_role_name) {
        toast.warning("Please fill in the field");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          id: role.id,
          name: this.new_role_name,
          role_type: "CLIENT",
        };
        console.log("update data", data);

        const res = await IdentitySmsService.post("admin/role/update", data, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });

        console.log("roles", res);
        if (res.status == 201) {
          toast.success(res.data.message);
          this.fetchRoles();
        }
      } catch (err) {
        console.log(err);
        if (err.status == 422) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },
    async deleteRole(role) {
      const toast = useToast();
      if (!role || !role.id) {
        toast.warning("Please Select a role to proceed");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          role_id: role.id,
        };
        console.log("update data", data);

        const res = await IdentitySmsService.post("admin/role/delete", data, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });

        console.log("roles", res);
        if (res.status == 201) {
          toast.success("role deleted" || res.data.message);
          this.fetchRoles();
          if (this.selectedRole.id == role.id) {
            this.selectedRole = {};
            this.selectedRoleName = "";
            this.selectedRolePermissions = [];
          }
        }
      } catch (err) {
        console.log(err);
        if (err.status == 422) {
          toast.error(err.response.data.message);
        } else if (err.status == 500) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },
    async permissionUpdate(role) {
      const toast = useToast();
      if (!role || !role.id) {
        toast.warning("Please Select a role to proceed");
        return;
      } else if (!this.selectedPermission) {
        toast.warning("Please Select a permission to proceed");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          module: this.modalPermission.module,
          permission: this.selectedPermission,
          role_id: role.id,
        };
        console.log("permission data", data);

        const res = await IdentitySmsService.post("admin/role/assign", data, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });

        console.log("roles", res);
        if (res.status == 201) {
          toast.success(res.data.message);
          this.fetchRoles();
        }
      } catch (err) {
        console.log(err);
        if (err.status == 422) {
          toast.error(err.response.data.message);
        } else if (err.status == 500) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },

    async handleAction() {
      const toast = useToast();
      if (!this.modalRole || !this.modalRole.id) {
        toast.warning("Please Select a role to proceed");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          module: this.modalPermission.module,
          permission: this.modalActionName,
          role_id: this.modalRole.id,
        };
        console.log("permission data", data);

        const res = await IdentitySmsService.post("admin/role/revoke", data, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });

        console.log("roles", res);
        if (res.status == 201) {
          toast.success(res.data.message);
          this.fetchRoles();
        }
      } catch (err) {
        console.log(err);
        if (err.status == 422) {
          toast.error(err.response.data.message);
        } else if (err.status == 500) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchRoles();
  },
};
</script>

<style scoped>
.contact-groups-div {
  background-color: #e5e5e5;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  width: calc(50% - 10px);
  margin-top: 10px;
}

.main-contact-group-container {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
}

.main-contact-group-container-upper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}

.search-div {
  display: flex;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}

.contact-group-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.active-status {
  color: green;
  font-weight: bold;
}

.inactive-status {
  color: red;
  font-weight: bold;
}

.choose-container {
  position: relative;
  text-align: center;
  cursor: pointer;
}

.button-group {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 2px;
  z-index: 100;
  display: block;
  background-color: white;
  padding: 4px;
}

.mobile_menu-new {
  display: none;
}
.contact-group-list {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  scrollbar-width: thin;
  scrollbar-color: #ccc #f1f1f1;
  /* scrollbar-color: #ccc black;  */
}

.contact-group-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 120px;
  max-width: 150px;
  height: 100px;
  background-color: #f1f1f1;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.contact-group-list::-webkit-scrollbar {
  height: 8px;
}

.contact-group-list::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.contact-group-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

@media screen and (max-width: 568px) {
  .contact-group-inner {
    width: 100%;
  }
  .mobile_menu-new {
    display: block;
    float: right;
  }
}
</style>
